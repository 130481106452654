import { IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import Business from '../../../types/business.type';
import { singularTitle } from '../../../pages/Businesses';
import Create from './Create';
import Search from './Search';
import { trash } from 'ionicons/icons';
type Props = {
    item: Business | null
    setItem: (item: Business | null) => void;
}
const Field: React.FC<Props> = ({ item, setItem }) => {

    return (
        <>
            <IonItem>
                {item ? <div className='contact'>
                    <h4>{singularTitle} Details</h4>
                    <h5>{item.name}</h5>
                    <div><IonText>{item.email}</IonText></div>
                    <IonText>{item.phone}</IonText>
                </div> :
                    <IonLabel>Select {singularTitle}</IonLabel>}
                <Search setItem={setItem} />
                <Create setItem={setItem} />
                <IonButton slot='end' color="danger" fill='clear' size='small' onClick={() => setItem(null)}><IonIcon icon={trash} /></IonButton>
            </IonItem>
        </>
    );
};

export default Field;
