import { IonButton, IonIcon, IonSpinner} from '@ionic/react';

import {  useState } from 'react';
import { trash } from 'ionicons/icons';
import { useAppContext } from '../../services/app-context.service';
import Booking from '../../types/booking.type';
import { apiBaseUrl } from '../../config';
import { routeRootPath } from '../../configs/reservation.crud.config';

type Props = {
  item: Booking
  onDelete: () => void;
}
const Delete: React.FC<Props> = ({ item, onDelete }) => {
  const store = useAppContext() as any

  const [loading, SetLoading] = useState(false)

  function onClick() {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + "/" + item.id, requestOptions).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        onDelete()
      }
    })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
      })
  }
  return (
    <div style={{ marginTop: 20 }}>

      <IonButton expand='block' color='danger' fill='outline' onClick={onClick}>
        {loading ? <IonSpinner /> : <>
          <IonIcon icon={trash} slot='start' />
          Delete
        </>}
      </IonButton>
    </div>
  );
};

export default Delete;
