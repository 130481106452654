import { IonButton, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonModal, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import TopBar from '../components/TopBar';
import { add, save, trash } from 'ionicons/icons';
import { useAppContext } from '../services/app-context.service';
import { useEffect, useRef, useState } from 'react';
import VehicleType from '../types/vehicle.type';
import { apiBaseUrl } from '../config';

const singularTitle = 'Vehicle Type'
const pluralTitle = 'Vehicle Types'
const routeRootPath = 'admin/vehicle-types'
type FormProps = {
  item?: VehicleType
  onSave: (item: VehicleType) => void;
}
const Form: React.FC<FormProps> = ({ item, onSave }) => {
  const store = useAppContext() as any

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, SetLoading] = useState(false)

  const name = useRef<HTMLIonInputElement>(null)
  const max_passenger = useRef<HTMLIonInputElement>(null)
  const driver_per_miles = useRef<HTMLIonInputElement>(null)
  const driver_per_min = useRef<HTMLIonInputElement>(null)
  const customer_per_miles = useRef<HTMLIonInputElement>(null)
  const customer_per_min = useRef<HTMLIonInputElement>(null)

  useEffect(() => {
    if (name.current && item) name.current.value = item.name
    if (max_passenger.current && item) max_passenger.current.value = item.max_passenger
    if (driver_per_miles.current && item) driver_per_miles.current.value = item.driver_per_miles
    if (driver_per_min.current && item) driver_per_min.current.value = item.driver_per_min
    if (customer_per_miles.current && item) customer_per_miles.current.value = item.customer_per_miles
    if (customer_per_min.current && item) customer_per_min.current.value = item.customer_per_min
  }, [item])

  function getNewValue() {
    const newItem = {} as VehicleType
    if (name.current?.value) {
      newItem.name = name.current.value.toString()
    }
    if (max_passenger.current?.value) {
      newItem.max_passenger = parseInt(max_passenger.current.value.toString())
    }
    if (driver_per_miles.current?.value) {
      newItem.driver_per_miles = parseFloat(driver_per_miles.current.value.toString())
    }
    if (driver_per_min.current?.value) {
      newItem.driver_per_min = parseFloat(driver_per_min.current.value.toString())
    }
    if (customer_per_miles.current?.value) {
      newItem.customer_per_miles = parseFloat(customer_per_miles.current.value.toString())
    }
    if (customer_per_min.current?.value) {
      newItem.customer_per_min = parseFloat(customer_per_min.current.value.toString())
    }
    return newItem
  }
  function isValid() {
    const newItem = getNewValue()
    if (item) {
      return newItem.name && newItem.driver_per_miles && newItem.driver_per_min && newItem.customer_per_miles && newItem.customer_per_miles
    } else {
      return JSON.stringify(newItem) !== JSON.stringify(item)
    }
  }
  function onChange() {
    setButtonDisabled(!isValid())
  }

  function onSubmit() {
    if (loading) {
      return null
    }
    SetLoading(true)
    setButtonDisabled(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const data = getNewValue()
    const requestOptions = {
      method: item ? 'PUT' : 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + (item ? "/" + item.id : ""), requestOptions).then((res) => (res.json()))
      .then((res) => {
        // console.log(res)
        onSave(res as VehicleType)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
        setButtonDisabled(false)
      })
  }
  return (
    <>
      <IonItem color="light">
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput ref={name} clearInput={true} onIonChange={onChange} />
      </IonItem>
      <IonItem color="light">
        <IonLabel position='floating'>Max Passanger Capacity</IonLabel>
        <IonInput ref={max_passenger} clearInput={true} onIonChange={onChange} type='number' />
      </IonItem>
      <IonItemGroup className='invoice-edit'>
        <IonItem color="light">
          <IonRow>
            <IonCol size='3' offset='6' style={{ textAlign: "center" }}>
              <b>Client</b>
            </IonCol>
            <IonCol size='3' style={{ textAlign: "center" }}>
              <b>Driver</b>
            </IonCol>
          </IonRow>
        </IonItem>
        <IonItem color="light">
          <IonRow>
            <IonCol size='6'>
              Rate Per Mile
            </IonCol>
            <IonCol size='3'>
              <IonInput ref={customer_per_miles} className='client' type='number' onIonChange={onChange} />
            </IonCol>
            <IonCol size='3'>
              <IonInput ref={driver_per_miles} className='driver' type='number' onIonChange={onChange} />
            </IonCol>
          </IonRow>
        </IonItem>
        <IonItem color="light">
          <IonRow>
            <IonCol size='6'>
              Rate Per Min
            </IonCol>
            <IonCol size='3'>
              <IonInput ref={customer_per_min} className='client' type='number' onIonChange={onChange} />
            </IonCol>
            <IonCol size='3'>
              <IonInput ref={driver_per_min} className='driver' type='number' onIonChange={onChange} />
            </IonCol>
          </IonRow>
        </IonItem>
        <div style={{ marginTop: 20 }}>

          <IonButton disabled={buttonDisabled} expand='block' color='dark' onClick={onSubmit}>
            {loading ? <IonSpinner /> : <>
              <IonIcon icon={save} slot='start' />
              {item && item.id ? 'Update' : 'Create'}
            </>}
          </IonButton>
        </div>
      </IonItemGroup>
    </>
  );
};
type DeleteProps = {
  item: VehicleType
  onDelete: () => void;
}
const Delete: React.FC<DeleteProps> = ({ item, onDelete }) => {
  const store = useAppContext() as any

  const [loading, SetLoading] = useState(false)

  function onClick() {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + "/" + item.id, requestOptions).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        onDelete()
      }
    })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
      })
  }
  return (
    <div style={{ marginTop: 20 }}>

      <IonButton expand='block' color='danger' fill='outline' onClick={onClick}>
        {loading ? <IonSpinner /> : <>
          <IonIcon icon={trash} slot='start' />
          Delete
        </>}
      </IonButton>
    </div>
  );
};
type CreateProps = {
  items: VehicleType[]
  setItems: (item: VehicleType[]) => void
}
const Create: React.FC<CreateProps> = ({ items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onSave(item: VehicleType) {
    setItems([...items, item])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Create New {singularTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form onSave={onSave} />
        </IonContent>
      </IonModal>
    </>
  );
};
type EditProps = {
  index: number
  items: VehicleType[]
  setItems: (item: VehicleType[]) => void
}
const Edit: React.FC<EditProps> = ({ index, items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onUpdate(item: VehicleType) {
    items[index] = item
    setItems([...items])
    modal.current?.dismiss()
  }
  function onDelete() {
    items.splice(index, 1)
    setItems([...items])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonItem button={true} onClick={() => modal.current?.present()}>{items[index].name}</IonItem>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Edit {singularTitle} #{items[index].id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form item={items[index]} onSave={onUpdate} />
          <Delete item={items[index]} onDelete={onDelete} />
        </IonContent>
      </IonModal>
    </>
  );
};
const VehicleTypes: React.FC = () => {
  const store = useAppContext() as any
  const [items, setItems] = useState([] as VehicleType[])
  const [loading, SetLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    // const paramsObj = { page: 1 } as any
    // const params = new URLSearchParams(paramsObj);

    // fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))

    fetch(apiBaseUrl + routeRootPath, requestOptions).then((res) => (res.json()))
      .then((res) => {
        setItems(res)
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <TopBar title={pluralTitle} />
      <IonContent>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        <IonItemGroup className='crud-items'>
          {items.map((item, index) => <Edit key={index} index={index} items={items} setItems={setItems} />)}
        </IonItemGroup>
        {!loading && items.length < 1 && <div className="container">
          <IonText><h1>No {singularTitle} Found</h1></IonText>
        </div>}
        <Create items={items} setItems={setItems} />
      </IonContent>
    </>
  );
};

export default VehicleTypes;
