import { IonContent, IonItem,  IonModal, IonText,  useIonViewDidEnter } from '@ionic/react';
import { useRef, useState } from 'react';

const NotificationModal: React.FC<any> = () => {
  const [data,setData] = useState({} as any)
  const modal = useRef() as any
  useIonViewDidEnter(()=>{
  })
  return (
    <IonModal ref={modal} trigger="open-modal" initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]}>
      <IonContent>
        {!!data.title && <IonItem>
          <div>
            <IonText color='primary'><h4>{data.title}</h4></IonText>
          </div>
        </IonItem>}
        {!!data.body && <IonItem>
          <div>
            <p>{data.body}</p>
          </div>
        </IonItem>}
      </IonContent>
    </IonModal>
  );
};

export default NotificationModal;
