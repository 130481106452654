import { IonBadge, IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, notifications, person, power } from 'ionicons/icons';
import { apiBaseUrl, appSubDir } from '../config';
import { defaultData, useAppContext, useAppContextUpdater } from '../services/app-context.service';
import { useHistory } from 'react-router-dom';
type Props = {
  title: string
  backBtn?: boolean
}

const TopBar: React.FC<Props> = (props) => {
  const data = useAppContext() as any
  const setData = useAppContextUpdater() as any
  const history = useHistory()
  async function afterLogout() {
    window.localStorage.removeItem('user')
    setData(defaultData)
    window.location.replace(appSubDir)
  }
  function doLogOut() {
    if (data.user.token) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + data.user.token);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(apiBaseUrl + "logout", requestOptions as RequestInit)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.error('error', error))
        .finally(afterLogout)
    } else {
      afterLogout()
    }
  }
  function goBack() {
    history.goBack()
  }
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {props.backBtn ? <IonButton onClick={goBack}>
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton> :
            <IonMenuButton></IonMenuButton>}
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
        <IonButton fill='clear' slot="end" color="dark" className='notification'>
          <IonIcon icon={notifications}></IonIcon>
          <IonBadge color="danger">11</IonBadge>
        </IonButton>
        <IonButton fill='clear' slot="end" color="dark">
          <IonIcon icon={person}></IonIcon>
        </IonButton>
        <IonButton onClick={doLogOut} fill='clear' slot="end" color="dark">
          <IonIcon icon={power}></IonIcon>
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default TopBar;
