import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonText, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import './AutoCompleteModal.scss';
import { useRef, useState } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import BookingPoint from '../../types/booking-point.type';
import airlines from '../../data/airlines.data';
import AirelineSelectorModal from './AirelineSelectorModal';
import Airline from '../../types/airline.type';
import { googleApiKey } from '../../configs/config.private';
import { trash } from 'ionicons/icons';
// import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
type Props = {
  flightInfoEnabled: boolean
  placeholder: string
  title: string
  onUpdate: any
  currentPoint: BookingPoint
  elementIndex: number
}
const AutoCompleteModal: React.FC<Props> = ({ elementIndex, currentPoint, flightInfoEnabled, placeholder, title, onUpdate }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const airlineModal = useRef<HTMLIonModalElement>(null)
  const flightInputRef = useRef<HTMLIonInputElement>(null)
  const dateTimeRef = useRef<HTMLIonDatetimeElement>(null)
  const dateTimeModal = useRef<HTMLIonModalElement>(null)
  const [address, SetAddress] = useState({} as BookingPoint)
  const [selectedAirline, setSelectedAirline] = useState({} as Airline);
  useIonViewDidEnter(() => {
    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     console.log('Push received: Home' + JSON.stringify(notification));
    //   }
    // );
    // PushNotifications.addListener('pushNotificationActionPerformed', (notification:any) => {
    //   console.log('Push notification action performed', notification.data.title, notification.data.body);
    // });
    const tabBar = document.querySelector('ion-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  })

  function onChange(e: any) {
    SetAddress({
      ...address,
      address: e.value.description,
      place_id: e.value.place_id,
      is_airport: e.value.types.includes('airport')
    })
  }
  const onAirlineChange = (selected: Airline) => {
    setSelectedAirline(selected);
    const newIteam = {} as BookingPoint
    newIteam.flight_airline_name = selected.name
    newIteam.flight_airline_iata = selected.iata
    SetAddress({ ...address, ...newIteam })
    airlineModal.current?.dismiss();
  }
  function onFlightNoChange() {
    const flight_no = flightInputRef.current && flightInputRef.current.value ? flightInputRef.current.value : ""
    SetAddress({
      ...address, flight_no
    } as BookingPoint)
  }
  function onDateTimeChange(e: any) {
    const newIteam = {} as BookingPoint
    newIteam.arrival_at = e.target.value
    SetAddress({ ...address, ...newIteam })
  }
  function clearDateTime() {
    const newIteam = {} as BookingPoint
    newIteam.arrival_at = ""
    SetAddress({ ...address, ...newIteam })
  }
  function confirm() {
    if (!address.address) {
      alert("No Address Selected")
      return null
    }
    modal.current?.dismiss(address, 'confirm');
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      // console.log(`Hello, ${ev.detail.data}!`);
      onUpdate(ev.detail.data)
    }
  }
  return (
    <>
      <div onClick={() => modal.current?.present()}>
        <div>
          <IonText
            className='address-field'
            color={currentPoint.address ? "dark" : "medium"}
          >{currentPoint.address ? currentPoint.address : placeholder}</IonText>
        </div>
        {address.arrival_at && <>
          <IonText color="dark" style={{ fontSize: 12 }}>Date & Time : </IonText>
          <IonText style={{ fontSize: 12 }}
            color="medium">{new Date(Date.parse(address.arrival_at)).toLocaleString()} </IonText>
        </>}
        {address.flight_airline_iata && address.flight_airline_iata && <>
          <IonText color="dark" style={{ fontSize: 12 }}>Flight No : </IonText>
          <IonText style={{ fontSize: 12 }}
            color="medium">{address.flight_airline_iata}{address.flight_no} </IonText>
        </>}
      </div>
      <IonModal ref={modal} keepContentsMounted={true} initialBreakpoint={0.90} breakpoints={[0, 0.25, 0.5, 0.90]} onWillDismiss={(ev) => onWillDismiss(ev)}>
        <IonHeader>
          <IonToolbar color='medium'>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()}>
                Confirm
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <IonItem>
            <IonLabel color={!!address.arrival_at ? "dark" : "medium"}>Date & Time</IonLabel>
            <IonDatetimeButton slot='end' datetime={"datetime-" + elementIndex}></IonDatetimeButton>
            {!!address.arrival_at && <IonButton onClick={clearDateTime} slot='end' fill='clear' color="danger"><IonIcon icon={trash} /></IonButton>}
            <IonModal keepContentsMounted={true}>
              <IonDatetime min={new Date().toISOString()} onIonChange={onDateTimeChange} id={"datetime-" + elementIndex}></IonDatetime>
            </IonModal>
          </IonItem>
          <div className='autocomplete'>
            <GooglePlacesAutocomplete autocompletionRequest={{
              componentRestrictions: { country: "uk" }
            }}
              apiKey={googleApiKey} selectProps={{ placeholder, onChange }} />
          </div>
          {flightInfoEnabled && address.is_airport && <IonItem>
            <IonText
              style={{ width: selectedAirline.iata ? "40%" : "100%" }}
              color="dark"
              onClick={() => airlineModal.current?.present()}>Airline</IonText>
            {selectedAirline.iata && <IonText
              style={{ width: "60%" }}
              color="medium"
              onClick={() => airlineModal.current?.present()}>{selectedAirline.iata} - {selectedAirline.name}</IonText>}
          </IonItem>}
          {flightInfoEnabled && address.is_airport && <IonItem className={`${false && 'ion-invalid'}`}>
            <IonLabel position="floating">Flight Number</IonLabel>
            <IonInput ref={flightInputRef} onIonChange={onFlightNoChange} clearInput={true} />
            {false && <IonNote slot='error'>Error</IonNote>}
          </IonItem>}
        </IonContent>
      </IonModal>
      <IonModal ref={airlineModal}>
        <AirelineSelectorModal
          title="Select Airline"
          items={airlines}
          selectedItem={selectedAirline}
          onSelectionCancel={() => airlineModal.current?.dismiss()}
          onSelectionChange={onAirlineChange}
        />
      </IonModal>

    </>
  );
};

export default AutoCompleteModal;
