import { Redirect, Route } from 'react-router-dom';
import {
  IonMenu,
  IonPage,
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from '../pages/Home';
import Menu from '../components/Menu';
import { appSubDir } from '../config';
import Reservations from '../pages/Reservations';
import Account from '../pages/Account';
import UsersPage from '../pages/users/UsersPage';
import UserEditorPage from '../pages/users/Editor.page';
import DriverHome from '../pages/drivers/DriverHome';
import ReviewRequested from '../pages/drivers/ReviewRequested';
import Documents from '../pages/drivers/Documents';
import VehicleTypes from '../pages/VehicleTypes';
import DriverTeams from '../pages/DriverTeams';
import Drivers from '../pages/Drivers';
import Customers from '../pages/Customers';
import Businesses from '../pages/Businesses';


const PrivateRoutes: React.FC = () => (
  <IonReactRouter>
    <IonMenu type='push' id="sidebar" contentId="menu">
      <Menu/>
    </IonMenu>
    <input className='dummy' name="email" type="text"/>
    <input className='dummy' type="password"/>
    <IonPage id='menu'>
      <IonRouterOutlet>
        <Route exact path={appSubDir+"home"}>
          <Home />
        </Route>
        <Route exact path={appSubDir+"reservations"}>
          <Reservations />
        </Route>
        <Route exact path={appSubDir+"account"}>
          <Account />
        </Route>
        <Route exact path={appSubDir+"users"}>
          <UsersPage />
        </Route>
        <Route exact path={appSubDir+"drivers"}>
          <Drivers/>
        </Route>
        <Route exact path={appSubDir+"customers"}>
          <Customers/>
        </Route>
        <Route exact path={appSubDir+"businesses"}>
          <Businesses/>
        </Route>
        <Route exact path={appSubDir+"driver-home"}>
          <DriverHome />
        </Route>
        <Route exact path={appSubDir+"drivers/review-requested"}>
          <ReviewRequested />
        </Route>
        <Route exact path={appSubDir+"drivers/documents"}>
          <Documents />
        </Route>
        <Route exact path={appSubDir+"users/:action"}>
          <UserEditorPage />
        </Route>
        <Route exact path={appSubDir+"settings/vehicle-types"}>
          <VehicleTypes />
        </Route>
        <Route exact path={appSubDir+"settings/driver-teams"}>
          <DriverTeams />
        </Route>
        <Route>
          <Redirect to={appSubDir+"reservations"} />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  </IonReactRouter>
);

export default PrivateRoutes;
