import { IonCol, IonContent, IonGrid, IonIcon, IonRow, IonSpinner, IonText} from '@ionic/react';
import { useEffect, useState } from 'react';
import TopBar from '../../components/TopBar';
import { apiBaseUrl, appSubDir } from '../../config';
import { useAppContext } from '../../services/app-context.service';
import { Link } from 'react-router-dom';
import { carSport } from 'ionicons/icons';

type FetchResponse = {
  active:number
  inactive:number
  underReview:number
}

const DriverHome = () => {
  const store = useAppContext() as any
  const [data, setData] = useState<FetchResponse|null>(null)
  const [loading, SetLoading] = useState(false)
  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;

    fetch(apiBaseUrl + "admin/driver" , requestOptions).then((res) => (res.json()))
      .then((res:FetchResponse) => {
        setData(res)
      })
      .catch((err) => console.error("fetch error",err))
      .finally(() => SetLoading(false))
  }
  useEffect(()=>{
    fetchData()
  },[])
  return <>
    <TopBar title='Driver Management'/>
    <IonContent fullscreen>
      <IonGrid className='dashboard'>
        <IonRow>
          <IonCol>
            <Link to='/doctors' className={`tile light`}>
              {
                loading ? 
                <IonSpinner name="lines"></IonSpinner> :(
                  data ? 
                  <IonText color="tertiary"><h1>{data.active}</h1></IonText> : 
                  <IonIcon size="large" className='place-holder' icon={carSport}/>
                )
              }
              <h3>Active Drivers</h3>
            </Link>
          </IonCol>
          <IonCol>
            <Link to='/calculator' className={`tile light`}>
              {
                loading ? 
                <IonSpinner name="lines"></IonSpinner> :(
                  data ? 
                  <IonText color="tertiary"><h1>{data.inactive}</h1></IonText> : 
                  <IonIcon size="large" className='place-holder' icon={carSport}/>
                )
              }
              <h3>Inactive Drivers</h3>
            </Link>
          </IonCol>
          <IonCol>
            <Link to={appSubDir+"drivers/review-requested"} className={`tile light`}>
              {
                loading ? 
                <IonSpinner name="lines"></IonSpinner> :(
                  data ? 
                  <IonText color="tertiary"><h1>{data.underReview}</h1></IonText> : 
                  <IonIcon size="large" className='place-holder' icon={carSport}/>
                )
              }
              <h3>Review Requested</h3>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </>
};
export default DriverHome