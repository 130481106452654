import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, person, search } from 'ionicons/icons';
import { useRef } from 'react';
import User from '../../types/user.type';
import BookingContactSearch from './BookingContactSearch';
import BookingContactCreate from './BookingContactCreate';
type Props = {
    customer?: User
    setCustomer?: (item: User) => void;
}
const BookingContactPicker: React.FC<Props> = ({ customer,setCustomer }) => {

    return (
        <>
            <IonItem>
                <IonIcon icon={person} slot="start" />
                {customer && <div className='contact'>
                    <h5>{customer.f_name} {customer.l_name}</h5>
                    <div><IonText>{customer.email}</IonText></div>
                    <IonText>{customer.phone}</IonText>
                </div>}
                {!customer && <IonLabel color="medium">Billing Contact</IonLabel>}
                <BookingContactSearch setCustomer={setCustomer}/>
                <BookingContactCreate setCustomer={setCustomer}/>
            </IonItem>
        </>
    );
};

export default BookingContactPicker;
