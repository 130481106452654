import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonPage, IonSpinner, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { mail, key,card,cloudUpload } from 'ionicons/icons';
import { useRef, useState } from 'react';
import AuthFooter from '../../components/AuthFooter';
import { apiBaseUrl, appSubDir } from '../../config';
import { useHistory } from 'react-router-dom';
import './Login.scss';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import NotificationModal from '../../components/NotificationModal';

const Login: React.FC = () => {
  const history = useHistory()
  const data = useAppContext() as any
  const setData = useAppContextUpdater() as any
  const email = useRef({value:""} as any)
  const password = useRef({value:""} as any)
  const [emailError,setEmailError]  = useState("")
  const [passError,setPassError]  = useState("")
  const [buttonLoading,SetButtonLoading] = useState(false)
  const [disabled,SetDisabled] = useState(true)
  const [presentToast] = useIonToast();
  useIonViewDidEnter(()=>{
    const sidebar = document.getElementById('sidebar');
    if (sidebar !== null) {
      sidebar.style.display = 'none';
    }
  })
  const onSubmit = () =>{
    // history.replace('/home')
    const data = {email:email.current.value,password:password.current.value,role:'admin'}
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    SetButtonLoading(true)
    SetDisabled(true)
    fetch(apiBaseUrl+"login", requestOptions as RequestInit)
      .then(response => {
          if(response.status===200){
              return response.json()
          }else{
              throw response.json()
          }
      })
      .then(user => {
        email.current.value = ""
        password.current.value = ""
        presentToast({
          message: "Login Success",
          duration: 3000,
          cssClass: 'custom-toast',
          animated: true,
          color: "success",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ],
        })
        setData((current:any)=>{
          current.user = user
          return current
        })
        window.localStorage.setItem('user',JSON.stringify(user))
        window.location.replace(appSubDir+"reservations")
      })
      .catch(function (errPromise) {
        errPromise.then((error: any)=>{
            if (error.message) {
                if (error.message) {
                    if (error.message.email) {
                        setEmailError(error.message.email.join(' '))
                    }
                    if (error.message.password) {
                        setPassError(error.message.password.join(' '))
                    }
                    if (typeof error.message == 'string') {
                        presentToast({
                          message: error.message,
                          duration: 3000,
                          cssClass: 'custom-toast',
                          animated: true,
                          color: "danger",
                          buttons: [
                            {
                              text: 'Dismiss',
                              role: 'cancel'
                            }
                          ],
                        })
                    }
                }
            }else{
              console.error("error response",error);
            }
        })
    }).finally(()=>{
        SetButtonLoading(false)
        SetDisabled(!isValid())
    })
  }
  const isValid = ()=>{
    return !!email.current.value && !!password.current.value
  }
  const onEmailChange = () =>{
    if(email.current.value){
      setEmailError("")
    }else{
      setEmailError("Cannot be blank")
    }
    SetDisabled(!isValid())
  }
  const onPassChange = () =>{
    if(password.current.value){
      setPassError("")
    }else{
      setPassError("Cannot be blank")
    }
    SetDisabled(!isValid())
  }
  return (
    <IonPage>
      <IonContent id="login" fullscreen>
        <div className="container">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Admin Login</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem className={`${!!emailError && 'ion-invalid'}`}>
                <IonLabel position="floating"><IonIcon icon={mail}/>Email Or Phone Number</IonLabel>
                <IonInput clearInput={true} ref={email} onIonChange={onEmailChange}/>
                {!!emailError && <IonNote slot='error'>{emailError}</IonNote>}
              </IonItem>
              <IonItem className={`${!!passError && 'ion-invalid'}`}>
                <IonLabel position="floating"><IonIcon icon={key}/>Password</IonLabel>
                <IonInput type='password' clearInput={true} ref={password} onIonChange={onPassChange}/>
                {!!passError && <IonNote slot='error'>{passError}</IonNote>}
              </IonItem>
              <IonButton disabled={disabled} onClick={onSubmit} expand='block' color="success">
                {!buttonLoading && <IonIcon icon={cloudUpload} slot="start"/>}
                {buttonLoading && <IonSpinner slot="start" name="lines-sharp"></IonSpinner>}
                {buttonLoading ? "Verifing":"Login"}
              </IonButton>
            </IonCardContent>
          </IonCard>
        </div>
        <NotificationModal/>
      </IonContent>
    </IonPage>
  );
};

export default Login;
