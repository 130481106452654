import { IonButton, IonContent, IonHeader, IonIcon,  IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { add } from 'ionicons/icons';
import { useRef } from 'react';
import Business from '../../../types/business.type';
import { Form,singularTitle } from '../../../pages/Businesses';
type Props = {
    setItem: (item: Business) => void;
}
const Create: React.FC<Props> = ({ setItem }) => {
    const modal = useRef<HTMLIonModalElement>(null)

    function onOpen() {
        modal.current?.present()
    }
    function onSave(item: Business) {
        setItem(item)
        modal.current?.dismiss()
    }
    return (
        <>
            <IonButton slot='end' color="dark" fill='clear' size='small' onClick={onOpen}><IonIcon icon={add} /></IonButton>
            <IonModal ref={modal} initialBreakpoint={0.77} breakpoints={[0, 0.25, 0.5, 0.77, 0.90]} >
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle>Create New {singularTitle}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <Form onSave={onSave} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default Create;
