import { IonButton, IonCol, IonDatetime, IonDatetimeButton, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonModal, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea } from '@ionic/react';
import { location, addCircle, calendar, carSport, pencil, play, stopCircle, trash, documentOutline, save, alarm } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import BookingPoint from '../../types/booking-point.type';
import AutoCompleteModal from './AutoCompleteModal';
import { useAppContext } from '../../services/app-context.service';
import { apiBaseUrl } from '../../config';
import './Form.scss';
import mapApiLoader from '../../services/map-loader.services';
import BookingContactPicker from './BookingContactPicker';
import User from '../../types/user.type';
import { reservationTypes, reservationStatusList } from '../../data/reservation.data';
import BookingDriverPicker from './BookingDriverPicker';
import Booking from '../../types/booking.type';
import { routeRootPath } from '../../configs/reservation.crud.config';
import VehicleType from '../../types/vehicle.type';
import { getFormatedMins } from '../../functions/time.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';

const timeOffset = new Date().getTimezoneOffset()
type Props = {
  vehicleTypes: VehicleType[]
  item?: Booking
  onSave: (item: Booking) => void;
}
const Form: React.FC<Props> = ({ vehicleTypes, item, onSave }) => {
  const store = useAppContext() as any
  const vehicleTypeRef = useRef<HTMLIonSelectElement>(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [loading, SetLoading] = useState(false)
  const [estimateing, setEstimateing] = useState(false)
  const [estimated_total_mins, setEstimatedTotalMins] = useState(item && item.estimated_total_mins > 0 ? item.estimated_total_mins : 0)
  const [estimated_total_miles, setEstimatedTotalMiles] = useState(item && item.estimated_total_miles > 0 ? item.estimated_total_miles : 0)
  const [customer, setCustomer] = useState<User | undefined>(item?.customer)
  const [driver, setDriver] = useState<User | undefined>(item?.driver)


  const [stopPoints, SetStopPoints] = useState(item && item.points ? item.points : [{}, {}] as BookingPoint[])
  const dateTimeRef = useRef<HTMLIonDatetimeElement>(null)
  const status = useRef<HTMLIonSelectElement>(null)
  const typeRef = useRef<HTMLIonSelectElement>(null)


  const customer_per_miles = useRef<HTMLIonInputElement>(null)
  const customer_total_miles = useRef<HTMLIonInputElement>(null)
  const customer_per_min = useRef<HTMLIonInputElement>(null)
  const customer_total_hrs = useRef<HTMLIonInputElement>(null)
  const customer_total_mins = useRef<HTMLIonInputElement>(null)
  const customer_fixed_charge = useRef<HTMLIonInputElement>(null)
  const customer_invoice_total = useRef<HTMLIonInputElement>(null)
  const customer_paid = useRef<HTMLIonInputElement>(null)

  const driver_per_miles = useRef<HTMLIonInputElement>(null)
  const driver_total_miles = useRef<HTMLIonInputElement>(null)
  const driver_per_min = useRef<HTMLIonInputElement>(null)
  const driver_total_hrs = useRef<HTMLIonInputElement>(null)
  const driver_total_mins = useRef<HTMLIonInputElement>(null)
  const driver_fixed_charge = useRef<HTMLIonInputElement>(null)
  const driver_invoice_total = useRef<HTMLIonInputElement>(null)
  const driver_paid = useRef<HTMLIonInputElement>(null)
  const estimatedProfit = useRef<HTMLIonInputElement>(null)
  const profit = useRef<HTMLIonInputElement>(null)

  
  const driver_late = useRef<HTMLIonInputElement>(null)
  const driver_away = useRef<HTMLIonInputElement>(null)

  const waiting_time_charge = useRef<HTMLIonInputElement>(null)
  const parking_charge = useRef<HTMLIonInputElement>(null)
  const congrestion_charge = useRef<HTMLIonInputElement>(null)
  const airport_dropoff_charge = useRef<HTMLIonInputElement>(null)


  const driver_instruction = useRef<HTMLIonTextareaElement>(null)
  const admin_note = useRef<HTMLIonTextareaElement>(null)

  useEffect(() => {
    if (status.current && item) status.current.value = item.status
    if (typeRef.current && item) typeRef.current.value = item.type
    if (dateTimeRef.current && item) dateTimeRef.current.value = new Date(Date.parse(item.start_at) - timeOffset * 60000).toISOString()
    if (vehicleTypeRef.current && item) vehicleTypeRef.current.value = item.vehicle_type_id


    if (customer_per_miles.current && item) customer_per_miles.current.value = item.customer_per_miles
    if (customer_total_miles.current && item) customer_total_miles.current.value = item.customer_total_miles
    if (customer_per_min.current && item) customer_per_min.current.value = item.customer_per_min
    if (customer_total_hrs.current && item) customer_total_hrs.current.value = item.customer_total_hrs
    if (customer_total_mins.current && item) customer_total_mins.current.value = item.customer_total_mins
    if (customer_fixed_charge.current && item) customer_fixed_charge.current.value = item.customer_fixed_charge
    if (customer_invoice_total.current && item) customer_invoice_total.current.value = item.customer_invoice_total
    if (customer_paid.current && item) customer_paid.current.value = item.customer_paid

    if (driver_per_miles.current && item) driver_per_miles.current.value = item.driver_per_miles
    if (driver_total_miles.current && item) driver_total_miles.current.value = item.driver_total_miles
    if (driver_per_min.current && item) driver_per_min.current.value = item.driver_per_min
    if (driver_total_hrs.current && item) driver_total_hrs.current.value = item.driver_total_hrs
    if (driver_total_mins.current && item) driver_total_mins.current.value = item.driver_total_mins
    if (driver_fixed_charge.current && item) driver_fixed_charge.current.value = item.driver_fixed_charge
    if (driver_invoice_total.current && item) driver_invoice_total.current.value = item.driver_invoice_total
    if (driver_paid.current && item) driver_paid.current.value = item.driver_paid


    if (waiting_time_charge.current && item) waiting_time_charge.current.value = item.waiting_time_charge
    if (parking_charge.current && item) parking_charge.current.value = item.parking_charge
    if (congrestion_charge.current && item) congrestion_charge.current.value = item.congrestion_charge
    if (airport_dropoff_charge.current && item) airport_dropoff_charge.current.value = item.airport_dropoff_charge
    if (driver_instruction.current && item) driver_instruction.current.value = item.driver_instruction
    if (admin_note.current && item) admin_note.current.value = item.admin_note

    if (driver_late.current && item) driver_late.current.value = item.driver_late
    if (driver_away.current && item) driver_away.current.value = item.driver_away

  }, [item])

  function getNewValue() {
    const newItem = {} as Booking
    if (customer) {
      newItem.customer_id = customer.id
      newItem.customer = customer
    }
    if (driver) {
      newItem.driver_id = driver.id
      newItem.driver = driver
    }
    if (status.current?.value) {
      newItem.status = status.current.value.toString()
    }
    if (typeRef.current?.value) {
      newItem.type = typeRef.current.value.toString()
    }
    if (dateTimeRef.current?.value) {
      newItem.start_at = dateTimeRef.current.value.toString()
    }
    if (vehicleTypeRef.current?.value) {
      newItem.vehicle_type_id = vehicleTypeRef.current.value.toString()
    }
    if (stopPoints) {
      newItem.points = stopPoints
    }
    if (estimated_total_miles) {
      newItem.estimated_total_miles = estimated_total_miles
    }
    if (estimated_total_mins) {
      newItem.estimated_total_mins = estimated_total_mins
    }

    if (customer_per_miles.current?.value) {
      newItem.customer_per_miles = parseFloat(customer_per_miles.current.value.toString()) || 0
    }
    if (customer_total_miles.current?.value) {
      newItem.customer_total_miles = parseFloat(customer_total_miles.current.value.toString()) || 0
    }
    if (customer_per_min.current?.value) {
      newItem.customer_per_min = parseFloat(customer_per_min.current.value.toString()) || 0
    }
    if (customer_total_hrs.current?.value) {
      newItem.customer_total_hrs = parseFloat(customer_total_hrs.current.value.toString()) || 0
    }
    if (customer_total_mins.current?.value) {
      newItem.customer_total_mins = parseFloat(customer_total_mins.current.value.toString()) || 0
    }
    if (customer_fixed_charge.current?.value) {
      newItem.customer_fixed_charge = parseFloat(customer_fixed_charge.current.value.toString()) || 0
    }
    if (customer_invoice_total.current?.value) {
      newItem.customer_invoice_total = parseFloat(customer_invoice_total.current.value.toString()) || 0
    }
    if (customer_paid.current?.value) {
      newItem.customer_paid = parseFloat(customer_paid.current.value.toString()) || 0
    }

    if (driver_per_miles.current?.value) {
      newItem.driver_per_miles = parseFloat(driver_per_miles.current.value.toString()) || 0
    }
    if (driver_total_miles.current?.value) {
      newItem.driver_total_miles = parseFloat(driver_total_miles.current.value.toString()) || 0
    }
    if (driver_per_min.current?.value) {
      newItem.driver_per_min = parseFloat(driver_per_min.current.value.toString()) || 0
    }
    if (driver_total_hrs.current?.value) {
      newItem.driver_total_hrs = parseFloat(driver_total_hrs.current.value.toString()) || 0
    }
    if (driver_total_mins.current?.value) {
      newItem.driver_total_mins = parseFloat(driver_total_mins.current.value.toString()) || 0
    }
    if (driver_fixed_charge.current?.value) {
      newItem.driver_fixed_charge = parseFloat(driver_fixed_charge.current.value.toString()) || 0
    }
    if (driver_invoice_total.current?.value) {
      newItem.driver_invoice_total = parseFloat(driver_invoice_total.current.value.toString()) || 0
    }
    if (driver_paid.current?.value) {
      newItem.driver_paid = parseFloat(driver_paid.current.value.toString()) || 0
    }


    if (driver_late.current?.value) {
      newItem.driver_late = parseFloat(driver_late.current.value.toString()) || 0
    }
    if (driver_away.current?.value) {
      newItem.driver_away = parseFloat(driver_away.current.value.toString()) || 0
    }

    if (waiting_time_charge.current?.value) {
      newItem.waiting_time_charge = parseFloat(waiting_time_charge.current.value.toString()) || 0
    }
    if (parking_charge.current?.value) {
      newItem.parking_charge = parseFloat(parking_charge.current.value.toString()) || 0
    }
    if (congrestion_charge.current?.value) {
      newItem.congrestion_charge = parseFloat(congrestion_charge.current.value.toString()) || 0
    }
    if (airport_dropoff_charge.current?.value) {
      newItem.airport_dropoff_charge = parseFloat(airport_dropoff_charge.current.value.toString()) || 0
    }
    newItem.estimated_total_mins = estimated_total_mins
    newItem.estimated_total_miles = estimated_total_miles


    if (driver_instruction.current?.value) {
      newItem.driver_instruction = driver_instruction.current.value.toString()
    }
    if (admin_note.current?.value) {
      newItem.admin_note = admin_note.current.value.toString()
    }
    return newItem
  }

  function onSubmit() {
    if (loading) {
      return null
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const data = getNewValue()
    if (!data.customer_id) {
      alert("No Customer was selected")
      return null
    }
    if (!data.vehicle_type_id) {
      alert("No Vehcle Type was selected")
      return null
    }
    if (!data.start_at) {
      alert("No Start Time was selected")
      return null
    }
    if (!isAllAddressReady()) {
      alert("No Location was selected")
      return null
    }
    if (!data.customer_invoice_total) {
      alert("Caustomer Invoice Total Was not calculated")
      return null
    }
    if (!data.driver_invoice_total) {
      alert("Driver Invoice Total Was not calculated")
      return null
    }
    SetLoading(true)
    setButtonDisabled(true)
    const requestOptions = {
      method: item ? 'PUT' : 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + (item ? "/" + item.id : ""), requestOptions).then((res) => (res.json()))
      .then((res: any) => {
        // console.log(res)
        data.identifier = res.identifier
        onSave(data)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
        setButtonDisabled(false)
      })
  }
  function addStopPointField() {
    stopPoints.splice(stopPoints.length - 1, 0, {} as any)
    SetStopPoints([...stopPoints])
  }
  function removeStopPointField(index: number) {
    stopPoints.splice(index, 1)
    SetStopPoints([...stopPoints])
    calculatePricing();
  }
  function isValid() {
    // const newItem = getNewValue()
    // if (item) {
    //   return newItem.status && newItem.driver_per_miles && newItem.driver_per_min && newItem.customer_per_miles && newItem.customer_per_miles
    // } else {
    //   return JSON.stringify(newItem) !== JSON.stringify(item)
    // }
    return !!dateTimeRef.current && !!dateTimeRef.current.value && isAllAddressReady() && !fromToSame()
  }
  function isAllAddressReady() {
    return stopPoints.filter((points) => !points.place_id).length === 0;
  }

  function fromToSame() {
    return stopPoints.length === 2 && stopPoints[0].place_id === stopPoints[1].place_id;
  }

  function onChange() {
    // setButtonDisabled(!isValid())
    setButtonDisabled(!isValid())
  }
  function updateStopPointField(index: number, newDate: BookingPoint) {
    stopPoints[index] = newDate
    SetStopPoints([...stopPoints])
    onChange()
    if (isAllAddressReady()) {
      calculatePricing();
    }
  }
  function onVehicleTypeChange(e: CustomEvent) {
    const vehicle_type_id = parseInt(vehicleTypeRef.current?.value) || 0
    const vehicleType = vehicleTypes.find((item) => item.id === vehicle_type_id)
    if (customer_per_min.current) {
      customer_per_min.current.value = vehicleType?.customer_per_min
    }
    if (customer_per_miles.current) {
      customer_per_miles.current.value = vehicleType?.customer_per_miles
    }
    if (driver_per_min.current) {
      driver_per_min.current.value = vehicleType?.driver_per_min
    }
    if (driver_per_miles.current) {
      driver_per_miles.current.value = vehicleType?.driver_per_miles
    }
  }
  function onEstimationReady() {
    const hours = Math.floor(estimated_total_mins / 60);
    const min = estimated_total_mins - hours * 60
    if (customer_total_hrs.current && hours > 0) {
      customer_total_hrs.current.value = hours
    }
    if (customer_total_mins.current && min > 0) {
      customer_total_mins.current.value = min
    }
    if (customer_total_miles.current && estimated_total_miles > 0) {
      customer_total_miles.current.value = estimated_total_miles
    }
    if (driver_total_hrs.current && hours > 0) {
      driver_total_hrs.current.value = hours
    }
    if (driver_total_mins.current && min > 0) {
      driver_total_mins.current.value = min
    }
    if (driver_total_miles.current && estimated_total_miles > 0) {
      driver_total_miles.current.value = estimated_total_miles
    }
  }
  useEffect(()=>{
    onEstimationReady()
  },[estimated_total_mins,estimated_total_miles])
  function onInvoiceChange() {
    const reservation = getNewValue()
    let customerInvoiceTotal = 0
    if (reservation.customer_per_min && reservation.customer_total_hrs) customerInvoiceTotal += (reservation.customer_per_min * (reservation.customer_total_hrs * 60))
    if (reservation.customer_per_min && reservation.customer_total_mins) customerInvoiceTotal += (reservation.customer_per_min * reservation.customer_total_mins)
    if (reservation.customer_per_miles && reservation.customer_total_miles) customerInvoiceTotal += (reservation.customer_per_miles * reservation.customer_total_miles)
    if (reservation.customer_fixed_charge) customerInvoiceTotal += reservation.customer_fixed_charge
    if (reservation.waiting_time_charge) customerInvoiceTotal += reservation.waiting_time_charge
    if (reservation.parking_charge) customerInvoiceTotal += reservation.parking_charge
    if (reservation.congrestion_charge) customerInvoiceTotal += reservation.congrestion_charge
    if (reservation.airport_dropoff_charge) customerInvoiceTotal += reservation.airport_dropoff_charge

    let driverInvoiceTotal = 0
    if (reservation.driver_per_min && reservation.driver_total_hrs) driverInvoiceTotal += (reservation.driver_per_min * (reservation.driver_total_hrs * 60))
    if (reservation.driver_per_min && reservation.driver_total_mins) driverInvoiceTotal += (reservation.driver_per_min * reservation.driver_total_mins)
    if (reservation.driver_per_miles && reservation.driver_total_miles) driverInvoiceTotal += (reservation.driver_per_miles * reservation.driver_total_miles)
    if (reservation.driver_fixed_charge) driverInvoiceTotal += reservation.driver_fixed_charge
    if (reservation.waiting_time_charge) driverInvoiceTotal += reservation.waiting_time_charge
    if (reservation.parking_charge) driverInvoiceTotal += reservation.parking_charge
    if (reservation.congrestion_charge) driverInvoiceTotal += reservation.congrestion_charge
    if (reservation.airport_dropoff_charge) driverInvoiceTotal += reservation.airport_dropoff_charge

    // console.log(reservation)
    if (customer_invoice_total.current) {
      customer_invoice_total.current.value = customerInvoiceTotal.toFixed(2)
    }
    if (driver_invoice_total.current) {
      driver_invoice_total.current.value = driverInvoiceTotal.toFixed(2)
    }
    if (estimatedProfit.current) {
      estimatedProfit.current.value = (customerInvoiceTotal - driverInvoiceTotal).toFixed(2)
    }

  }
  function onPaymentChange() {
    const reservation = getNewValue()
    let result = 0
    if (reservation.customer_paid) result += reservation.customer_paid
    if (reservation.driver_paid) result -= reservation.driver_paid

    if (profit.current) {
      profit.current.value = (result).toFixed(2)
    }

  }

  function calculatePricing() {
    if (!isAllAddressReady()) {
      return null
    }
    setEstimateing(true);
    // console.log(stopPoints)
    // const points = [{placeId:'ChIJ6W3FzTRydkgRZ0H2Q1VT548'},{placeId:'ChIJHWVqoPqPcUgROYxIBZwvPWo'},{placeId:'ChIJUS3lzJ-bcUgR0Q61KAY44No'}] as BookingPoint[]
    // const origins = points.slice(0,-1) as google.maps.Place[]
    // const destinations = points.slice(1) as google.maps.Place[]
    const origins = stopPoints.slice(0, -1).map((item) => ({ placeId: item.place_id } as google.maps.Place))
    const destinations = stopPoints.slice(1).map((item) => ({ placeId: item.place_id } as google.maps.Place))
    mapApiLoader.load().then(async () => {

      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins,
          destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        } as google.maps.DistanceMatrixRequest, callback);

      function callback(response: google.maps.DistanceMatrixResponse | null, status: google.maps.DistanceMatrixStatus) {
        if (response && status === "OK") {
          let distanceInMeter = 0
          let timeInSec = 0
          origins.map((origin, index) => {
            distanceInMeter += response.rows[index].elements[index].distance.value
            timeInSec += response.rows[index].elements[index].duration.value
          })
          // console.log(timeDistance.distanceInMeter, timeDistance.timeInSec)
          setEstimatedTotalMins(Math.floor(timeInSec / 60));
          setEstimatedTotalMiles(Math.round(distanceInMeter / 1609.34))
        } else {
          console.log("Something went wrong")
        }
        setEstimateing(false)
      }
    });
    // console.log(origins)
    // console.log(destinations)
  }
  const loadingComponent = <IonItem>
    <IonLabel>Estimating Price</IonLabel>
    <IonSpinner name="lines-small"></IonSpinner>
  </IonItem>;

  return (
    <>
      <section>
        <IonItem color='dark'>
          <IonLabel>Details</IonLabel>
        </IonItem>
        <BookingContactPicker customer={customer} setCustomer={setCustomer} />
        <BookingDriverPicker driver={driver} setDriver={setDriver} />
        {/* <IonItem>
          <IonIcon icon={alarm} slot="start" />
          <IonLabel>Driver Will Late</IonLabel>
          <IonInput slot='end' placeholder='Type' ref={driver_late} type='number'/>
          <IonLabel slot='end'>Mins</IonLabel>
        </IonItem>
        <IonItem>
          <IonText slot="start"><FontAwesomeIcon icon={faRoad}></FontAwesomeIcon></IonText>
          <IonLabel>Driver Away</IonLabel>
          <IonInput slot='end' placeholder='Type' ref={driver_away} type='number'/>
          <IonLabel slot='end'>Miles</IonLabel>
        </IonItem> */}
        <IonItem>
          <IonIcon icon={documentOutline} slot="start" />
          <IonLabel>Status</IonLabel>
          <IonSelect slot='end' interface="action-sheet" ref={status}>
            {reservationStatusList.map((item, index) => (<IonSelectOption key={index} value={item}>{item
              .replace("DA", "Driver Allocated (DA)")
              .replace("DOS", "Driver Offer Sent (DOS)")
              .replace("POB", "Passenger On Board (POB)")
              .replace('DOW', 'Driver On The Way (DOW)')}</IonSelectOption>))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonIcon icon={documentOutline} slot="start" />
          <IonLabel>Type</IonLabel>
          <IonSelect slot='end' interface="action-sheet" ref={typeRef}>
            {reservationTypes.map((item, index) => (<IonSelectOption key={index} value={item}>{item}</IonSelectOption>))}
          </IonSelect>
        </IonItem>
        <IonItem className={`${false && 'ion-invalid'}`}>
          <IonIcon icon={calendar} slot="start" />
          <IonLabel color="medium">Start Time</IonLabel>
          <IonDatetimeButton slot='end' datetime="datetime"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime ref={dateTimeRef} id="datetime"></IonDatetime>
          </IonModal>

          {false && <IonNote slot='error'>Error</IonNote>}
        </IonItem>
        <IonItem>
          <IonIcon icon={carSport} slot="start" />
          <IonLabel>Vehicle Type</IonLabel>
          <IonSelect ref={vehicleTypeRef} onIonChange={onVehicleTypeChange} slot='end' interface="action-sheet">
            {vehicleTypes.map((item, index) => (<IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>))}
          </IonSelect>
        </IonItem>
      </section>
      <section>
        <IonItem color='dark' style={{ marginTop: 20 }}>
          <IonLabel>Schedules</IonLabel>
        </IonItem>
        {stopPoints.map((stopPoint: BookingPoint, index: number) => {
          let startIcon, addBtn, removeBtn, placeholder, title, flightInfoEnabled
          if (index === 0) {
            startIcon = play
            addBtn = true
            removeBtn = false
            placeholder = "Enter Pickup Location"
            title = "Pickup Location"
            flightInfoEnabled = true
          } else if (index === (stopPoints.length - 1)) {
            startIcon = stopCircle
            addBtn = removeBtn = false
            placeholder = "Enter Dropoff Location"
            title = "Dropoff Location"
            flightInfoEnabled = true
          } else {
            startIcon = location
            addBtn = false
            removeBtn = true
            placeholder = "Enter Stop Location #" + (index)
            title = 'Stop Location #' + (index)
            flightInfoEnabled = false
          }

          return <IonItem key={index}>
            <IonIcon icon={startIcon} slot="start" />
            <AutoCompleteModal
              elementIndex={index}
              currentPoint={stopPoint}
              placeholder={placeholder}
              title={title}
              flightInfoEnabled={flightInfoEnabled}
              onUpdate={(newDate: BookingPoint) => updateStopPointField(index, newDate)} />
            {addBtn && stopPoints.length < 11 && <IonButton onClick={addStopPointField} fill='clear' color="dark" slot='end'><IonIcon icon={addCircle} /></IonButton>}
            {removeBtn && <IonButton onClick={() => removeStopPointField(index)} fill='clear' slot='end'><IonIcon icon={trash} /></IonButton>}
          </IonItem>
        })}
        {!estimateing && estimated_total_mins > 0 && <IonItem>
          <IonLabel>Total Estimated Time</IonLabel>
          <IonText slot='end'>{getFormatedMins(estimated_total_mins)}</IonText>
        </IonItem>}
        {!estimateing && estimated_total_miles > 0 && <IonItem>
          <IonLabel>Total Estimated Distance</IonLabel>
          <IonText slot='end'>{estimated_total_miles} Miles</IonText>
        </IonItem>}
      </section>
      <section>
        <IonItem color='dark' style={{ marginTop: 20 }}>
          <IonLabel>Billing & Payments</IonLabel>
        </IonItem>
        <IonItemGroup className='invoice-edit'>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='3' offset='6' style={{ textAlign: "center" }}>
                <b>Client</b>
              </IonCol>
              <IonCol size='3' style={{ textAlign: "center" }}>
                <b>Driver</b>
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Per Min
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_per_min} className='client' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_per_min} className='driver' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='3'>
                Duration
              </IonCol>
              <IonCol size='3'>
                <IonInput value='Hrs' readonly />
                <IonInput value='Mins' readonly />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_total_hrs} className='client' type='number' placeholder='Hrs' onIonChange={onInvoiceChange} />
                <IonInput ref={customer_total_mins} className='client' type='number' placeholder='Mins' onIonChange={onInvoiceChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_total_hrs} className='driver' type='number' placeholder='Hrs' onIonChange={onInvoiceChange} />
                <IonInput ref={driver_total_mins} className='driver' type='number' placeholder='Mins' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Per Mile
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_per_miles} className='client' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_per_miles} className='driver' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Total Miles
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_total_miles} className='client' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_total_miles} className='driver' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Fixed Rate Charge
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_fixed_charge} className='client' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_fixed_charge} className='driver' type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Waiting Time Charges
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={waiting_time_charge} type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Parking Charge
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={parking_charge} type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Congestion Charge
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={congrestion_charge} type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Airport Dropoff Charge
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={airport_dropoff_charge} type='number' onIonChange={onInvoiceChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light' style={{ borderTop: "2px solid var(--ion-color-dark)" }}>
            <IonRow>
              <IonCol size='6'>
                Invoice Total
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_invoice_total} className='client' readonly />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_invoice_total} className='driver' readonly />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Total Profit
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={estimatedProfit} readonly />
              </IonCol>
            </IonRow>
          </IonItem>
          {/* <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Total Payment Taken/Given
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={customer_paid} type='number' className='client' onIonChange={onPaymentChange} />
              </IonCol>
              <IonCol size='3'>
                <IonInput ref={driver_paid} type='number' className='driver' onIonChange={onPaymentChange} />
              </IonCol>
            </IonRow>
          </IonItem>
          <IonItem color='light'>
            <IonRow>
              <IonCol size='6'>
                Total Profit
              </IonCol>
              <IonCol size='6'>
                <IonInput ref={profit} readonly />
              </IonCol>
            </IonRow>
          </IonItem> */}
        </IonItemGroup>
      </section>
      <section>
        <IonItem color='dark' style={{ marginTop: 20 }}>
          <IonLabel>Addition Notes & Instrucations</IonLabel>
        </IonItem>
        <IonItem color='light'>
          <IonIcon icon={pencil} slot="start" />
          <IonLabel position="floating">Driver Instruction</IonLabel>
          <IonTextarea ref={driver_instruction} rows={4} />
        </IonItem>
        <IonItem color='light'>
          <IonIcon icon={pencil} slot="start" />
          <IonLabel position="floating">Admin Note</IonLabel>
          <IonTextarea ref={admin_note} rows={4} />
        </IonItem>
      </section>
      <div style={{ marginTop: 20 }}>
        <IonButton disabled={buttonDisabled} expand='block' color='dark' onClick={onSubmit}>
          {loading ? <IonSpinner /> : <>
            <IonIcon icon={save} slot='start' />
            {item && item.id ? 'Update' : 'Create'}
          </>}
        </IonButton>
      </div>
    </>
  );
};

export default Form;
