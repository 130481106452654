import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from '../pages/auth/Login';
import { appSubDir } from '../config';


const PublicRoutes: React.FC = () => (
  <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={appSubDir}>
          <Login />
        </Route>
        <Route path={appSubDir+":slug"}>
          <Redirect to={appSubDir} />
        </Route>
      </IonRouterOutlet>
  </IonReactRouter>
);

export default PublicRoutes;
