import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { carOutline, person } from 'ionicons/icons';
import User from '../../types/user.type';
import BookingDriverSearch from './BookingDriverSearch';
import BookingDriverCreate from './BookingDriverCreate';
type Props = {
    driver?: User
    setDriver?: (item: User) => void;
}
const BookingDriverPicker: React.FC<Props> = ({ driver,setDriver }) => {

    return (
        <>
            <IonItem>
                <IonIcon icon={carOutline} slot="start" />
                {driver && <div className='contact'>
                    <h5>{driver.f_name} {driver.l_name}</h5>
                    <div><IonText>{driver.email}</IonText></div>
                    <IonText>{driver.phone}</IonText>
                </div>}
                {!driver && <IonLabel color="medium">No Driver Allocated</IonLabel>}
                <BookingDriverSearch setDriver={setDriver}/>
                <BookingDriverCreate setDriver={setDriver}/>
            </IonItem>
        </>
    );
};

export default BookingDriverPicker;
