import { IonButton, IonItem, IonSpinner, IonText, } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl, appSubDir } from '../../config';
import { useAppContext } from '../../services/app-context.service';
import { useEffect, useState } from 'react';
import { Filter, routeRootPath } from '../../pages/Customers';
import User from '../../types/user.type';

const filter = {
  due: 'highest'
} as Filter
const TopDue: React.FC = () => {
  const store = useAppContext() as any
  const history = useHistory()
  const [loading, SetLoading] = useState(false)
  const [items, setItems] = useState([] as User[])
  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = { ...filter, per_page: 6 } as any
    const params = new URLSearchParams(paramsObj);
    // console.log(proposedFilter)
    // console.log(params.toString())
    fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
      .then((res) => {
        if (res.data) {
          setItems(res.data)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  useEffect(() => {
    fetchData()
  }, [])
  function onClick() {
    history.push(appSubDir + "customers", filter)
  }
  return (
    <>
      <IonItem>
        <h4>Top Customer Due</h4>
        <IonButton slot='end' fill='clear' color='dark' onClick={fetchData}>Refresh</IonButton>
        <IonButton onClick={onClick} slot='end' fill='clear' color='dark' >View All</IonButton>
      </IonItem>
      <div className='table-responsive dashboard'>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        {items.length > 0 && <table className='table-scroll'>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => <tr key={index}>
              <td>{item.f_name} {item.l_name} - {item.email}</td>
              <td>£{item.account_receivable - item.amount_received}</td>
            </tr>)}
          </tbody>
        </table>}
        {!loading && items && items.length < 1 && <div className="container">
          <IonText color='medium'><h1>No Customer On Due</h1></IonText>
        </div>}
      </div>
    </>
  );
};

export default TopDue;
