import { IonButton, IonContent, IonGrid, IonRow, IonSpinner, IonText } from '@ionic/react';
import TopBar from '../components/TopBar';
import './Reservations.scss';
import Booking, { BookingFilter } from '../types/booking.type';
import { useAppContext } from '../services/app-context.service';
import { useEffect, useRef, useState } from 'react';
import { apiBaseUrl } from '../config';
import { routeRootPath, singularTitle } from '../configs/reservation.crud.config';
import Create from '../components/reservations/Create';
import Edit from '../components/reservations/Edit';
import VehicleType from '../types/vehicle.type';
import Filter from '../components/reservations/Filter';
import { useLocation } from 'react-router';


const Reservations: React.FC = () => {
  const store = useAppContext() as any
  const location = useLocation<BookingFilter>()
  const [items, setItems] = useState([] as Booking[])
  const [vcehicleTypes, setVehicleTypes] = useState([] as VehicleType[])
  const [loading, SetLoading] = useState(false)
  const [filter, setFilter] = useState(location.state ? location.state : {
    page: 1
  } as BookingFilter)
  const lastPage = useRef(0)

  const fetchData = (proposedFilter:BookingFilter,append=false) => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = proposedFilter as any
    const params = new URLSearchParams(paramsObj);
    // console.log(proposedFilter)
    // console.log(params.toString())
    fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
      .then((res) => {
        if(append){
          setItems([...items,...res.data])
        }else{
          setItems(res.data)
        }
        lastPage.current = res.last_page
        proposedFilter.page = res.current_page
        setFilter({...filter,...proposedFilter})
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  const fetchVehicleTypes = () => {
    if (loading) {
      return null
    }
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;

    fetch(apiBaseUrl + 'admin/vehicle-types', requestOptions).then((res) => (res.json()))
      .then((res) => {
        setVehicleTypes(res)
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    fetchData(filter)
    fetchVehicleTypes()
  }, [])
  function onLoad(){
    const newFilter = filter
    newFilter.page++
    fetchData(newFilter,true)
  }

  return (
    <>
      <TopBar title='Reservations' />
      <IonContent fullscreen>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        {items && items.length > 0 && <IonGrid>
          <IonRow className='trips'>
            {items.map((item, index) => <Edit vehicleTypes={vcehicleTypes} key={index} index={index} items={items} setItems={setItems} />)}
          </IonRow>
        </IonGrid>}
        {!loading && filter.page < lastPage.current && <IonButton expand='block' style={{margin:'0 auto 10px',width:'50vw'}} onClick={onLoad} color='dark'>Load More</IonButton>}
        {!loading && items && items.length < 1 && <div className="container">
          <IonText><h1>No {singularTitle} Found</h1></IonText>
        </div>}
        <Create vehicleTypes={vcehicleTypes} items={items} setItems={setItems} />
        <Filter vehicleTypes={vcehicleTypes} filter={filter} onApplyFilter={fetchData} />
      </IonContent>
    </>
  );
};

export default Reservations;
