import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonSelect, IonSelectOption, IonSpinner, useIonToast } from '@ionic/react';
import { cloudUpload, home, idCard, key, mail, person, phonePortrait } from 'ionicons/icons';
import { apiBaseUrl } from '../../config';
import { useAppContext} from '../../services/app-context.service';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import User from '../../types/user.type';
import Role from '../../types/user-role.type';

type Props = {
  user:User
  onUpdate:any
  roles:Role[]
}
const UserForm: React.FC<Props> = ({user,roles,onUpdate}) => {
  const store = useAppContext() as any
  const history = useHistory()

  const fName = useRef({value:""} as any)
  const email = useRef({value:""} as any)
  const phone = useRef({value:""} as any)
  const lName = useRef({value:""} as any)
  const role = useRef({value:""} as any)
  const password = useRef({value:""} as any)
  const confirmPassword = useRef({value:""} as any)

  const [errMsg,SetErrMsg] = useState({} as any)

  const [buttonLoading,SetButtonLoading] = useState(false)
  const [disabled,SetDisabled] = useState(true)
  const [presentToast] = useIonToast();
  useEffect(()=>{
    setTimeout(()=>{
      fName.current.value = user.f_name
      lName.current.value = user.l_name
      email.current.value = user.email
      phone.current.value = user.phone
      password.current.value = ""
      confirmPassword.current.value = ""
      role.current.value = user.roles? user.roles[0].name : ""
    },100)
  },[user])
  const onChange = () =>{
    if(user.id){
      SetDisabled(!(
        fName.current && fName.current.value && 
        lName.current.value && 
        email.current.value && 
        phone.current.value && 
        role.current.value && 
        password.current.value == confirmPassword.current.value 
      ))
    }else{
      SetDisabled(!(
        fName.current && fName.current.value && 
        lName.current.value && 
        email.current.value && 
        phone.current.value && 
        role.current.value && 
        password.current.value && 
        confirmPassword.current.value && 
        password.current.value == confirmPassword.current.value 
      ))
    }
  }
  const onSubmit = () =>{
    
    SetButtonLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer "+store.user.token);
    const formdata = new FormData();
    formdata.append("f_name", fName.current.value.trim());
    formdata.append("l_name", lName.current.value.trim());
    formdata.append("email", email.current.value.trim());
    formdata.append("phone", phone.current.value.trim());
    formdata.append("password", password.current.value);
    formdata.append("confirmPassword", confirmPassword.current.value);
    formdata.append("role", role.current.value);
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    } as any
    const path = user.id ? user.id+"/update" : "create"
    fetch(apiBaseUrl+"admin/users/"+path,requestOptions).then((res)=>(res.text()))
    .then((res)=>{
      if(res == "success"){
        // toast.success('User added')
        if(user.id){
          password.current.value = ""
          confirmPassword.current.value = ""
        }else{
          fName.current.value = ""
          lName.current.value = ""
          email.current.value = ""
          phone.current.value = ""
          password.current.value = ""
          confirmPassword.current.value = ""
          role.current.value = ""
        }
        onUpdate()
        presentToast({
          message: user.id ? "Edit User Successful":"Create User Successful",
          duration: 3000,
          cssClass: 'custom-toast',
          animated: true,
          color: "success",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ],
        })
      }else{
        throw res
      }
    })
    .catch((err)=>{
      console.log(err)
      if(err.message){
        SetErrMsg(err.message)
      }
    })
    .finally(()=>SetButtonLoading(false))
  }
  return (
    <>
      <IonItem className={`${!!errMsg.f_name && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={person}/>First Name</IonLabel>
        <IonInput clearInput={true} ref={fName} onIonChange={onChange}/>
        {!!errMsg.f_name && <IonNote slot='error'>{errMsg.f_name.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.l_name && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={home}/>Last Name</IonLabel>
        <IonInput clearInput={true} ref={lName} onIonChange={onChange}/>
        {!!errMsg.l_name && <IonNote slot='error'>{errMsg.l_name.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.phone && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={phonePortrait}/>Mobile</IonLabel>
        <IonInput clearInput={true} ref={phone} onIonChange={onChange}/>
        {!!errMsg.phone && <IonNote slot='error'>{errMsg.phone.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.email && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={mail}/>Email</IonLabel>
        <IonInput clearInput={true} ref={email} onIonChange={onChange}/>
        {!!errMsg.email && <IonNote slot='error'>{errMsg.email.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.role && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={idCard}/>Role</IonLabel>
        <IonSelect ref={role} onIonChange={onChange}>
          {roles.map((item,index)=>(<IonSelectOption key={index} value={item.name}>{item.display_name}</IonSelectOption>)) }
        </IonSelect>
        {!!errMsg.role && <IonNote slot='error'>{errMsg.role.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.password && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={key}/>Password</IonLabel>
        <IonInput type='password' clearInput={true} ref={password} onIonChange={onChange}/>
        {!!errMsg.password && <IonNote slot='error'>{errMsg.password.join(', ')}</IonNote>}
      </IonItem>
      <IonItem className={`${!!errMsg.confirmPassword && 'ion-invalid'}`}>
        <IonLabel position="floating"><IonIcon icon={key}/>Confirm Password</IonLabel>
        <IonInput type='password' clearInput={true} ref={confirmPassword} onIonChange={onChange}/>
        {!!errMsg.confirmPassword && <IonNote slot='error'>{errMsg.confirmPassword.join(', ')}</IonNote>}
      </IonItem>
      <IonButton disabled={disabled} onClick={onSubmit} expand='block' color={user.id ? "medium":"dark"}>
        {!buttonLoading && <IonIcon icon={cloudUpload} slot="start"/>}
        {buttonLoading && <IonSpinner slot="start" name="lines-sharp"></IonSpinner>}
        {buttonLoading ? "Uploading Changes":(user.id ? "Save Changes":"Create User")}
      </IonButton>
    </>
  );
};

export default UserForm;
