export function getHours(timeInMin: number) {
	return Math.floor(timeInMin / 60);
}
export function getMins(timeInMin: number,hoursToExclude=0) {
	return timeInMin - hoursToExclude * 60
}
export function getFormatedMins(timeInMin: number) {
	const hours = getHours(timeInMin)
	const min = getMins(timeInMin,hours)
	let output = []
	if (hours > 0) {
		output.push(hours.toString().padStart(2, "0") + ' Hrs')
	}
	if (min > 0) {
		output.push(min.toString().padStart(2, "0") + ' Mins')
	}
	return output.join(" ")
}