import { IonButton, IonContent,IonIcon, IonInput, IonItem, IonLabel, IonSpinner } from '@ionic/react';
import { arrowForward, search } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import TopBar from '../../components/TopBar';
import { apiBaseUrl, appSubDir } from '../../config';
import { useAppContext } from '../../services/app-context.service';
import User from '../../types/user.type';
import { Link } from 'react-router-dom';

const ReviewRequested = () =>{
  const store = useAppContext() as any
  const [drivers,setDrivers] = useState<User[]>([])
  const [page,SetPage] = useState(1)
  const pages = useRef({last_page:1,current_page:1})
  const searchText = useRef({} as any)
  const [loading,SetLoading] = useState(false)
  const fetchData = ()=>{
    if(loading){
      console.log("was loading")
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer "+store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = {page:page.toString()} as any
    if(searchText.current.value){
      paramsObj.s = searchText.current.value
    }
    const params = new URLSearchParams(paramsObj);

    fetch(apiBaseUrl+"admin/drivers/review-requested?"+params.toString(),requestOptions).then((res)=>(res.json()))
    .then((res)=>{
      pages.current.last_page = res.last_page 
      pages.current.current_page = res.current_page
      setDrivers(res.data)
    })
    .catch((err)=>console.log(err))
    .finally(()=>SetLoading(false))
  }
  useEffect(()=>{
    fetchData()
  },[])
  function viewDocuments(user:User){
    console.log(user)
  }
  return <>
      <TopBar title='Review Requested'/>
      <IonContent className='ion-padding'>
              <IonItem>
                <IonLabel position="floating"><IonIcon icon={search}/>Search By name, email & Phone</IonLabel>
                <IonInput debounce={500} ref={searchText} clearInput={true} onIonChange={fetchData} defaultValue=""/>
              </IonItem>
              {loading && <div className='text-center'><IonSpinner color="primary" name="bubbles"/></div>}
              {!loading && <div className='table-responsive'>
                <table className='table-scroll'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {drivers.map((item:User,index)=>{
                      return <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.f_name} {item.l_name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.status}</td>
                        <td>
                          <Link to={{
                              pathname:appSubDir+"drivers/documents",
                              state:item
                            }}>
                            <IonButton fill='clear' color="tertiary">Documents<IonIcon slot='end' icon={arrowForward}/>
                            </IonButton>
                          </Link>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>}
          {/* {!loading && <div className='row'>
            <div className='col-md-12 d-flex justify-content-center'>
              <ul className='pagination'>
                  {pages.current.last_page > 1 &&[...Array(pages.current.last_page).keys()].map((item,index)=>{
                    const current = ((item+1) == pages.current.current_page)
                    return <li key={index} onClick={(e)=>{
                      e.preventDefault()
                      !current && SetPage(item+1)
                    }} 
                      className={current?'page-item active':'page-item'}>
                      <a href='#' className="page-link">{item+1}</a>
                    </li>
                  })}
              </ul>
            </div>
          </div>} */}
      </IonContent>
    </>
};
export default ReviewRequested