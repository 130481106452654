export const reservationTypes = [
  'Airport Transfer',
  "Point2Point",
  'As Directed',
  "Roadshow",
  'Date and Time of First Pick-up'
]
export const reservationStatusList = [
  'Processing',
  'DOS',//Driver Offer Sent
  "Confirmed",
  'DOW',//Driver On The Way
  'POB',//Passenger On Board
  'Disputed',
  "Completed",
  "Cancelled"
]