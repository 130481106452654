import {  IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem,  IonRow, IonSpinner, IonText, IonToggle } from '@ionic/react';
import { close, trash } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import TopBar from '../../components/TopBar';
import { apiBaseUrl, baseUrl } from '../../config';
import { useAppContext } from '../../services/app-context.service';
import User from '../../types/user.type';
import { ReviewStatus } from '../../types/document.type';
import { backTitles, frontTitles, titles, types } from '../../configs/driver-document.config';

const Documents = () => {
  const store = useAppContext() as any
  const location = useLocation()
  const driver = location.state as User
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus | null>(null)
  const [loading, SetLoading] = useState(false)
  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;

    fetch(apiBaseUrl + "admin/drivers/review-requested/" + driver.id + "/datails", requestOptions).then((res) => (res.json()))
      .then((res: ReviewStatus) => {
        setReviewStatus(res)
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  useEffect(() => {
    fetchData()
  }, [])
  function getDocTitle(type: string) {
    const index = types.findIndex((item) => (item === type))
    if (index > -1) {
      return titles[index]
    } else {
      return ""
    }
  }
  function getDocFrontTitle(type: string) {
    const index = types.findIndex((item) => (item === type))
    if (index > -1) {
      return frontTitles[index]
    } else {
      return ""
    }
  }
  function getDocBackTitle(type: string) {
    const index = types.findIndex((item) => (item === type))
    if (index > -1) {
      return backTitles[index]
    } else {
      return ""
    }
  }
  return <>
    <TopBar title='Review Request' />
    <IonContent className='ion-padding'>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonText slot='start' style={LebelStyle}><b>Driver Name</b></IonText>
              <IonText>{driver.f_name} {driver.l_name}</IonText>
            </IonItem>
            <IonItem>
              <IonText slot='start' style={LebelStyle}><b>Email</b></IonText>
              <IonText>{driver.email}</IonText>
            </IonItem>
            <IonItem>
              <IonText slot='start' style={LebelStyle}><b>phone</b></IonText>
              <IonText>{driver.phone}</IonText>
            </IonItem>
            <IonItem>
              <IonText slot='start' style={LebelStyle}><b>Active</b></IonText>
              <IonToggle checked={driver.status=="Active"} aria-label="Activate Driver"></IonToggle>
            </IonItem>
            {reviewStatus && <><IonItem title='Driver will not be able to upload or modify documents'>
              <IonText slot='start' style={LebelStyle}><b>Block Review Request</b></IonText>
              <IonToggle checked={reviewStatus.blockReviewRequest} aria-label="Lock Documents"></IonToggle>
            </IonItem>
            <IonItem>
              <IonText slot='start' style={LebelStyle}><b>Review Requested</b></IonText>
              <IonToggle checked={reviewStatus.reviewRequested}  aria-label="Review Requested"></IonToggle>
            </IonItem></>}
          </IonCol>
        </IonRow>
      </IonGrid>
      {loading && <div className='text-center'><IonSpinner color="primary" name="bubbles" /></div>}
      {!loading && reviewStatus && <div className='table-responsive'>
        <table className='table-scroll'>
          <thead>
            <tr>
              <th>Doc ID</th>
              <th>Document Type</th>
              <th>Documents</th>
              <th>Document No</th>
              <th>Expiry Date</th>
              <th>Verified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reviewStatus.documents.map((item, index) => {
              return <tr key={index}>
                <td>{item.id}</td>
                <td>{getDocTitle(item.type)}</td>
                <td>
                  <a href={baseUrl + `storage/driver-documents/${item.driver_id}/${item.type}/file_A-${item.file_A}`} title={getDocFrontTitle(item.type)}>
                    <img src={baseUrl + `storage/driver-documents/${item.driver_id}/${item.type}/file_A-${item.file_A}`} alt="" width={100} />
                  </a>
                  {item.file_B && <a href={baseUrl + `storage/driver-documents/${item.driver_id}/${item.type}/file_B-${item.file_B}`} title={getDocBackTitle(item.type)}>
                    <img src={baseUrl + `storage/driver-documents/${item.driver_id}/${item.type}/file_B-${item.file_B}`} alt="" width={100} />
                  </a>}
                </td>
                <td>{item.license_no}</td>
                <td>{item.expired_at}</td>
                <td><IonToggle checked={item.verified} aria-label="Activate Driver"></IonToggle>
                </td>
                <td>
                  <IonButton fill='outline' title='Delete' color="danger"><IonIcon icon={trash} /></IonButton>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>}
    </IonContent>
  </>
};
const LebelStyle = { width: "40%", textAlign: "right" };
export default Documents