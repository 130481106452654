import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { close, trashBin } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { apiBaseUrl } from '../../config';
import { useAppContext } from '../../services/app-context.service';
import User from '../../types/user.type';

type Props = {
  user:User
  onSuccess:any
}
const DeleteConfirmation: React.FC<Props> = ({user,onSuccess}) => {
  const store = useAppContext() as any
  const deleteModal = useRef<HTMLIonModalElement>(null);
  const [buttonLoading,SetButtonLoading] = useState(false)
  const [presentToast] = useIonToast();
  function present(){
    deleteModal.current?.present()
  }
  function dismiss(){
    deleteModal.current?.dismiss()
  }
  const deleteUser = () =>{
    SetButtonLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer "+store.user.token);
    
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    } as any
    fetch(apiBaseUrl+"admin/users/"+user.id,requestOptions).then((res)=>(res.text()))
    .then((res)=>{
      if(res == "success"){
        onSuccess()
        deleteModal.current?.dismiss()
        presentToast({
          message: "Delete User Successful",
          duration: 3000,
          cssClass: 'custom-toast',
          animated: true,
          color: "success",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ],
        })
      }else{
        throw res
      }
    })
    .catch((err)=>{
      if(err){
        presentToast({
          message: err,
          duration: 3000,
          cssClass: 'custom-toast',
          animated: true,
          color: "danger",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ],
        })
      }else{
        console.log(err)
        presentToast({
          message: "Something Went Wrong",
          duration: 3000,
          cssClass: 'custom-toast',
          animated: true,
          color: "danger",
          buttons: [
            {
              text: 'Dismiss',
              role: 'cancel'
            }
          ],
        })
      }
    })
    .finally(()=>SetButtonLoading(false))
  }
  return <>
      <IonButton onClick={present} fill='clear' color="danger"><IonIcon icon={trashBin}/></IonButton>
      <IonModal ref={deleteModal}>
        <IonContent>
          <IonToolbar>
            <IonTitle>Confirm Delete User</IonTitle>
          </IonToolbar>
          <IonList>
            <IonItem>
              <IonLabel>
                <h2>Full Name</h2>
                <p>{user.f_name} {user.l_name}</p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h2>Email</h2>
                <p>{user.email}</p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h2>Phone</h2>
                <p>{user.phone}</p>
              </IonLabel>
            </IonItem>
          </IonList>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton disabled={buttonLoading} color="danger" onClick={deleteUser} expand='block'><IonIcon icon={trashBin} slot="start"/>{buttonLoading?"Deleting":'Confirm Delete'}</IonButton>
              </IonCol>
              <IonCol>
                <IonButton color="light" onClick={dismiss} expand='block'><IonIcon icon={close} slot="start"/>Close</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
};
export default DeleteConfirmation