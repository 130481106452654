import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { person, search } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useAppContext } from '../../../services/app-context.service';
import Business from '../../../types/business.type';
import { apiBaseUrl } from '../../../config';
import { routeRootPath, singularTitle } from '../../../pages/Businesses';
type Props = {
    setItem: (item: Business) => void;
}
const Search: React.FC<Props> = ({ setItem }) => {
    const store = useAppContext() as any
    const modal = useRef<HTMLIonModalElement>(null)
    const searchText = useRef<HTMLIonInputElement>(null)
    const pages = useRef({ last_page: 1, current_page: 1 })
    const [items, setItems] = useState([] as Business[])
    const [loading, SetLoading] = useState(false)

    const fetchData = () => {
        if (loading) {
            return null
        }
        SetLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + store.user.token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        } as any;
        const paramsObj = { page: 1 } as any
        if (searchText.current?.value) {
            paramsObj.search = searchText.current.value
        }
        const params = new URLSearchParams(paramsObj);

        fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
            .then((res) => {
                pages.current.last_page = res.last_page
                pages.current.current_page = res.current_page
                setItems(res.data)
            })
            .catch((err) => console.log(err))
            .finally(() => SetLoading(false))
    }
    return (
        <>
            <IonButton slot='end' color="dark" fill='clear' size='small' onClick={() => modal.current?.present()}><IonIcon icon={search} /></IonButton>
            <IonModal ref={modal} initialBreakpoint={0.77} breakpoints={[0, 0.25, 0.5, 0.77, 0.90]} keepContentsMounted={true}>
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle>Search Exsisting {singularTitle}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem color="light">
                        <IonIcon icon={search} slot='start' />
                        <IonLabel position='floating'>Start Typing name, email or phone</IonLabel>
                        <IonInput clearInput={true} ref={searchText} debounce={300} onIonChange={fetchData} />
                    </IonItem>
                    {loading && <div className='container'><IonSpinner /></div>}
                    {!loading && items && items.map((item, index) => <IonItem key={index} button={true} onClick={() => {
                        if (setItem) {
                            setItem(item)
                            modal.current?.dismiss()
                        }
                    }}>
                        <IonIcon slot='start' size='large' icon={person} />
                        <div className='contact'>
                            <h5>{item.name}</h5>
                            <div><IonText>{item.email}</IonText></div>
                            <IonText>{item.phone}</IonText>
                        </div>
                    </IonItem>)}
                </IonContent>
            </IonModal>
        </>
    );
};

export default Search;
