import { IonBadge, IonButton, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import TopBar from '../components/TopBar';
import { add, closeCircleOutline, save, search, trash } from 'ionicons/icons';
import { useAppContext } from '../services/app-context.service';
import { useEffect, useRef, useState } from 'react';
import { apiBaseUrl } from '../config';
import { useLocation } from 'react-router';
import Business from '../types/business.type';

export const singularTitle = 'Business Account'
export const pluralTitle = 'Business Accounts'
export const routeRootPath = 'admin/businesses'
type FormProps = {
  item?: Business
  onSave: (item: Business) => void;
}
export const Form: React.FC<FormProps> = ({ item, onSave }) => {
  const store = useAppContext() as any

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, SetLoading] = useState(false)

  const name = useRef<HTMLIonInputElement>(null)
  const email = useRef<HTMLIonInputElement>(null)
  const phone = useRef<HTMLIonInputElement>(null)
  const credit_limit = useRef<HTMLIonInputElement>(null)
  const address = useRef<HTMLIonTextareaElement>(null)
  const status = useRef<HTMLIonSelectElement>(null)

  useEffect(() => {
    if (name.current && item) name.current.value = item.name
    if (email.current && item) email.current.value = item.email
    if (phone.current && item) phone.current.value = item.phone
    if (status.current && item) status.current.value = item.status
    if (address.current && item) address.current.value = item.address
    if (credit_limit.current && item) credit_limit.current.value = item.credit_limit
  }, [item])

  function getNewValue() {
    const newItem = {} as Business
    if (name.current?.value) {
      newItem.name = name.current.value.toString()
    }
    if (phone.current?.value) {
      newItem.phone = phone.current.value.toString()
    }
    if (email.current?.value) {
      newItem.email = email.current.value.toString()
    }
    if (status.current?.value) {
      newItem.status = status.current.value.toString()
    }
    if (address.current?.value) {
      newItem.address = address.current.value
    }
    if (credit_limit.current?.value) {
      newItem.credit_limit = parseFloat(credit_limit.current.value.toString()) || 0
    }else{
      newItem.credit_limit = 0
    }
    return newItem
  }
  function isValid() {
    const newItem = getNewValue()
    if (item) {
      return newItem.name && newItem.email && newItem.phone
    } else {
      return JSON.stringify(newItem) !== JSON.stringify(item)
    }
  }
  function onChange() {
    setButtonDisabled(!isValid())
  }

  function onSubmit() {
    if (loading) {
      return null
    }
    SetLoading(true)
    setButtonDisabled(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const data = getNewValue()
    const requestOptions = {
      method: item ? 'PUT' : 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + (item ? "/" + item.id : ""), requestOptions).then((res) => (res.json()))
      .then((res) => {
        // console.log(res)
        onSave(res as Business)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
        setButtonDisabled(false)
      })
  }
  return (
    <>
      <IonItem color="light">
        <IonLabel>Full Business Name</IonLabel>
        <IonInput slot='end' ref={name} clearInput={true} onIonChange={onChange} />
      </IonItem>
      <IonItem color="light">
        <IonLabel>Business Email</IonLabel>
        <IonInput slot='end' ref={email} clearInput={true} onIonChange={onChange} type='email' />
      </IonItem>
      <IonItem color="light">
        <IonLabel>Business Phone</IonLabel>
        <IonInput slot='end' ref={phone} clearInput={true} onIonChange={onChange} />
      </IonItem>
      <IonItem color="light">
        <IonLabel>Registerred Address</IonLabel>
        <IonTextarea slot='end' rows={4} ref={address} onIonChange={onChange} />
      </IonItem>
      <IonItem color="light">
        <IonLabel>Credit Limit</IonLabel>
        <IonText slot='end'>£</IonText>
        <IonInput slot='end' ref={credit_limit} clearInput={true} onIonChange={onChange} />
      </IonItem>
      <IonItem>
        <IonLabel>Status</IonLabel>
        <IonSelect slot='end' interface="action-sheet" ref={status} onIonChange={onChange} >
          <IonSelectOption value='Active'>Active</IonSelectOption>
          <IonSelectOption value='Inactive'>Inactive</IonSelectOption>
        </IonSelect>
      </IonItem>
      <div style={{ marginTop: 20 }}>

        <IonButton disabled={buttonDisabled} expand='block' color='dark' onClick={onSubmit}>
          {loading ? <IonSpinner /> : <>
            <IonIcon icon={save} slot='start' />
            {item && item.id ? 'Update' : 'Create'}
          </>}
        </IonButton>
      </div>
    </>
  );
};
type DeleteProps = {
  item: Business
  onDelete: () => void;
}
const Delete: React.FC<DeleteProps> = ({ item, onDelete }) => {
  const store = useAppContext() as any

  const [loading, SetLoading] = useState(false)

  function onClick() {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + "/" + item.id, requestOptions).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        onDelete()
      }
    })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
      })
  }
  return (
    <div style={{ marginTop: 20 }}>

      <IonButton expand='block' color='danger' fill='outline' onClick={onClick}>
        {loading ? <IonSpinner /> : <>
          <IonIcon icon={trash} slot='start' />
          Delete
        </>}
      </IonButton>
    </div>
  );
};
type CreateProps = {
  items: Business[]
  setItems: (item: Business[]) => void
}
const Create: React.FC<CreateProps> = ({ items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onSave(item: Business) {
    setItems([...items, item])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Create New {singularTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form onSave={onSave} />
        </IonContent>
      </IonModal>
    </>
  );
};
type EditProps = {
  index: number
  items: Business[]
  setItems: (item: Business[]) => void
}
const Edit: React.FC<EditProps> = ({ index, items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const item = items[index]
  function onUpdate(item: Business) {
    items[index] = item
    setItems([...items])
    modal.current?.dismiss()
  }
  function onDelete() {
    items.splice(index, 1)
    setItems([...items])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonItem className='contact' button={true} onClick={() => modal.current?.present()}>
        <IonText>
          <h5>{item.name}</h5>
          <div>{item.email}</div>
          {item.phone}
          <h5>{item.status}</h5>
        </IonText>
        <IonText slot='end' className='right'>
          {item.credit_limit > 0 && <>
            <h5>Credit Limit</h5>
            <div>£{item.credit_limit}</div>
          </>}
          {item.billed != item.paid && <>
            <h5>Due</h5>
            <div>£{item.billed - item.paid}</div>
          </>}
        </IonText>
      </IonItem>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Edit {singularTitle} #{items[index].id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form item={items[index]} onSave={onUpdate} />
          <Delete item={items[index]} onDelete={onDelete} />
          {item && <IonItem color="light">
            <IonLabel>Amount Billed</IonLabel>
            <IonText>£{item.billed}</IonText>
          </IonItem>}
          {item && <IonItem color="light">
            <IonLabel>Amount Received</IonLabel>
            <IonText>£{item.paid}</IonText>
          </IonItem>}
          {item && <IonItem color="light">
            <IonLabel>Amount Due</IonLabel>
            <IonText>£{item.billed - item.paid}</IonText>
          </IonItem>}
        </IonContent>
      </IonModal>
    </>
  );
};
type Filter = {
  page: number
  search: string
  status: string
  due: string
}
type FilterProps = {
  filter: Filter
  onApplyFilter: (item: Filter) => void
}
const Filter: React.FC<FilterProps> = ({ filter, onApplyFilter }) => {
  const modal = useRef<HTMLIonModalElement>(null)

  const searchRef = useRef<HTMLIonInputElement>(null)
  const status = useRef<HTMLIonSelectElement>(null)
  const due = useRef<HTMLIonSelectElement>(null)

  const [filterCount, setFilterCount] = useState(Object.keys(filter).filter(item => item !== "page").length)
  useEffect(() => {
    if (searchRef.current) searchRef.current.value = filter.search
    if (status.current) status.current.value = filter.status
    if (due.current) due.current.value = filter.due
  }, [])
  function getNewData() {
    const newFilter = { page: 1 } as Filter
    if (searchRef.current?.value) newFilter.search = searchRef.current.value as string
    if (status.current?.value) newFilter.status = status.current.value as string
    if (due.current?.value) newFilter.due = due.current.value as string
    return newFilter
  }
  function onChange() {
    const newFilter = getNewData()
    setFilterCount(Object.keys(newFilter).filter(item => item !== "page").length)
    onApplyFilter(newFilter)
  }
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={search}></IonIcon>
          {filterCount > 0 && <IonBadge className='filter'>{filterCount}</IonBadge>}
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} keepContentsMounted={true} initialBreakpoint={0.5} breakpoints={[0, 0.5, 0.9]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Filter {pluralTitle}</IonTitle>
            <IonButton slot='end' onClick={() => modal.current?.dismiss()} fill='clear' color='danger'><IonIcon icon={closeCircleOutline} /></IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem color='light'>
            <IonLabel position='floating'>Search By Name, Email Or Phone</IonLabel>
            <IonInput ref={searchRef} onIonChange={onChange} debounce={500} clearInput={true} />
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Status</IonLabel>
            <IonSelect ref={status} onIonChange={onChange} slot='end' interface="action-sheet">
              <IonSelectOption value={""}>Any</IonSelectOption>
              <IonSelectOption value={"Active"}>Active</IonSelectOption>
              <IonSelectOption value={"Inactive"}>Inactive</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Short By Due</IonLabel>
            <IonSelect ref={due} onIonChange={onChange} slot='end' interface="action-sheet">
              <IonSelectOption value={""}>Any</IonSelectOption>
              <IonSelectOption value={"highest"}>Descending</IonSelectOption>
              <IonSelectOption value={"lowest"}>Ascending</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  );
};

const Businesses: React.FC = () => {
  const store = useAppContext() as any
  const [items, setItems] = useState([] as Business[])
  const [loading, SetLoading] = useState(false)
  const location = useLocation<Filter>()
  const [filter, setFilter] = useState(location.state ? location.state : {
    page: 1
  } as Filter)
  const lastPage = useRef(0)

  const fetchData = (proposedFilter: Filter, append = false) => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = proposedFilter as any
    const params = new URLSearchParams(paramsObj);

    // fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))

    fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
      .then((res) => {
        if (append) {
          setItems([...items, ...res.data])
        } else {
          setItems(res.data)
        }
        lastPage.current = res.last_page
        proposedFilter.page = res.current_page
        setFilter({ ...filter, ...proposedFilter })
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  function onLoad() {
    const newFilter = filter
    newFilter.page++
    fetchData(newFilter, true)
  }
  useEffect(() => {
    fetchData(filter)
  }, [])

  return (
    <>
      <TopBar title={pluralTitle} />
      <IonContent>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        <IonItemGroup className='crud-items'>
          {items.map((item, index) => <Edit key={index} index={index} items={items} setItems={setItems} />)}
        </IonItemGroup>
        {!loading && filter.page < lastPage.current && <IonButton expand='block' style={{ margin: '0 auto 10px', width: '50vw' }} onClick={onLoad} color='dark'>Load More</IonButton>}
        {!loading && items.length < 1 && <div className="container">
          <IonText><h1>No {singularTitle} Found</h1></IonText>
        </div>}
        <Create items={items} setItems={setItems} />
        <Filter filter={filter} onApplyFilter={fetchData} />
      </IonContent>
    </>
  );
};

export default Businesses;
