import { IonButton, IonCol, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonModal, IonRow, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import TopBar from '../components/TopBar';
import { add, save, trash } from 'ionicons/icons';
import { useAppContext } from '../services/app-context.service';
import { useEffect, useRef, useState } from 'react';
import { apiBaseUrl } from '../config';
import DriverTeam from '../types/triver-team.type';

const singularTitle = 'Driver Team'
const pluralTitle = 'Driver Teams'
const routeRootPath = 'admin/driver-teams'
type FormProps = {
  item?: DriverTeam
  onSave: (item: DriverTeam) => void;
}
const Form: React.FC<FormProps> = ({ item, onSave }) => {
  const store = useAppContext() as any

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, SetLoading] = useState(false)

  const name = useRef<HTMLIonInputElement>(null)
  const description = useRef<HTMLIonTextareaElement>(null)

  useEffect(() => {
    if (name.current && item) name.current.value = item.name
    if (description.current && item) description.current.value = item.description
  }, [item])

  function getNewValue() {
    const newItem = {} as DriverTeam
    if (name.current?.value) {
      newItem.name = name.current.value.toString()
    }
    if (description.current?.value) {
      newItem.description = description.current.value.toString()
    }
    return newItem
  }
  function isValid() {
    const newItem = getNewValue()
    if (item) {
      return newItem.name
    } else {
      return JSON.stringify(newItem) != JSON.stringify(item)
    }
  }
  function onChange() {
    setButtonDisabled(!isValid())
  }

  function onSubmit() {
    if (loading) {
      return null
    }
    SetLoading(true)
    setButtonDisabled(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const data = getNewValue()
    const requestOptions = {
      method: item ? 'PUT' : 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + (item ? "/" + item.id : ""), requestOptions).then((res) => (res.json()))
      .then((res) => {
        // console.log(res)
        onSave(res as DriverTeam)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
        setButtonDisabled(false)
      })
  }
  return (
    <>
      <IonItem color="light">
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput ref={name} clearInput={true} onIonChange={onChange} />
      </IonItem>
      <IonItem color="light">
        <IonLabel position='floating'>Description</IonLabel>
        <IonTextarea ref={description} onIonChange={onChange} />
      </IonItem>
      <div style={{ marginTop: 20 }}>

        <IonButton disabled={buttonDisabled} expand='block' color='dark' onClick={onSubmit}>
          {loading ? <IonSpinner /> : <>
            <IonIcon icon={save} slot='start' />
            {item && item.id ? 'Update' : 'Create'}
          </>}
        </IonButton>
      </div>
    </>
  );
};
type DeleteProps = {
  item: DriverTeam
  onDelete: () => void;
}
const Delete: React.FC<DeleteProps> = ({ item, onDelete }) => {
  const store = useAppContext() as any

  const [loading, SetLoading] = useState(false)

  function onClick() {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    } as RequestInit;

    fetch(apiBaseUrl + routeRootPath + "/" + item.id, requestOptions).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        onDelete()
      }
    })
      .catch((err) => console.log(err))
      .finally(() => {
        SetLoading(false)
      })
  }
  return (
    <div style={{ marginTop: 20 }}>

      <IonButton expand='block' color='danger' fill='outline' onClick={onClick}>
        {loading ? <IonSpinner /> : <>
          <IonIcon icon={trash} slot='start' />
          Delete
        </>}
      </IonButton>
    </div>
  );
};
type CreateProps = {
  items: DriverTeam[]
  setItems: (item: DriverTeam[]) => void
}
const Create: React.FC<CreateProps> = ({ items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onSave(item: DriverTeam) {
    setItems([...items, item])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Create New {singularTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form onSave={onSave} />
        </IonContent>
      </IonModal>
    </>
  );
};
type EditProps = {
  index: number
  items: DriverTeam[]
  setItems: (item: DriverTeam[]) => void
}
const Edit: React.FC<EditProps> = ({ index, items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onUpdate(item: DriverTeam) {
    items[index] = item
    setItems([...items])
    modal.current?.dismiss()
  }
  function onDelete() {
    items.splice(index, 1)
    setItems([...items])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonItem button={true} onClick={() => modal.current?.present()}>{items[index].name}</IonItem>
      <IonModal ref={modal} initialBreakpoint={0.75} breakpoints={[0, 0.75]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Edit {singularTitle} #{items[index].id}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form item={items[index]} onSave={onUpdate} />
          <Delete item={items[index]} onDelete={onDelete} />
        </IonContent>
      </IonModal>
    </>
  );
};
const DriverTeams: React.FC = () => {
  const store = useAppContext() as any
  const [items, setItems] = useState([] as DriverTeam[])
  const [loading, SetLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = { page: 1 } as any
    const params = new URLSearchParams(paramsObj);

    fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
      .then((res) => {
        setItems(res.data)
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <TopBar title={pluralTitle} />
      <IonContent>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        <IonItemGroup className='crud-items'>
          {items.map((item, index) => <Edit key={index} index={index} items={items} setItems={setItems} />)}
        </IonItemGroup>
        {!loading && items.length < 1 && <div className="container">
          <IonText><h1>No {singularTitle} Found</h1></IonText>
        </div>}
        <Create items={items} setItems={setItems} />
      </IonContent>
    </>
  );
};

export default DriverTeams;
