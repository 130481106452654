import { IonButton, IonItem, IonSpinner, IonText, } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl, appSubDir } from '../../config';
import Booking, { BookingFilter } from '../../types/booking.type';
import { useAppContext } from '../../services/app-context.service';
import { useEffect, useState } from 'react';
import { routeRootPath } from '../../configs/reservation.crud.config';

const filter = { driver_allocated: 'No' } as BookingFilter
const DriverAllocated: React.FC = () => {
  const store = useAppContext() as any
  const history = useHistory()
  const [loading, SetLoading] = useState(false)
  const [items, setItems] = useState([] as Booking[])
  const fetchData = () => {
    if (loading) {
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = { ...filter, per_page: 6 } as any
    const params = new URLSearchParams(paramsObj);
    // console.log(proposedFilter)
    // console.log(params.toString())
    fetch(apiBaseUrl + routeRootPath + "?" + params.toString(), requestOptions).then((res) => (res.json()))
      .then((res) => {
        if(res.data){
          setItems(res.data)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => SetLoading(false))
  }
  useEffect(()=>{
    fetchData()
  },[])
  function onClick() {
    history.push(appSubDir + "reservations", filter)
  }
  return (
    <>
      <IonItem>
        <h4>Driver Was Not Allocated</h4>
        <IonButton slot='end' fill='clear' color='dark' >Refresh</IonButton>
        <IonButton onClick={onClick} slot='end' fill='clear' color='dark' >View All</IonButton>
      </IonItem>
      <div className='table-responsive dashboard'>
        {loading && <div className="container">
          <IonSpinner />
        </div>}
        {items.length > 0 && <table className='table-scroll'>
          <thead>
            <tr>
              <th>Trip Ref</th>
              <th>Customer</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item,index)=><tr key={index}>
              <td>{item.identifier}</td>
              <td>{item.customer?.f_name} {item.customer?.l_name}- {item.customer?.email}</td>
              <td>{item.status}</td>
            </tr>)}
          </tbody>
        </table>}
        {!loading && items && items.length < 1 && <div className="container">
          <IonText color='medium'><h1>No Booking Found</h1></IonText>
        </div>}
      </div>
    </>
  );
};

export default DriverAllocated;
