import { IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { add, closeCircleOutline } from 'ionicons/icons';
import { useRef } from 'react';
import Booking from '../../types/booking.type';
import Form from './Form';
import { singularTitle } from '../../configs/reservation.crud.config';
import VehicleType from '../../types/vehicle.type';
type Props = {
  vehicleTypes: VehicleType[]
  items: Booking[]
  setItems: (item: Booking[]) => void
}
const Create: React.FC<Props> = ({vehicleTypes, items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  function onSave(item: Booking) {
    // console.log(item)
    setItems([item,...items])
    modal.current?.dismiss()
  }

  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} className='full-height half-width align-end'>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Create New {singularTitle}</IonTitle>
            <IonButton slot='end'  onClick={() => modal.current?.dismiss()} fill='clear' color='danger'><IonIcon icon={closeCircleOutline}/></IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" className='ion-padding'>
          <Form vehicleTypes={vehicleTypes}  onSave={onSave}/>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Create;
