import { IonCol, IonContent, IonItem, IonText, IonHeader, IonModal, IonTitle, IonToolbar, IonButton, IonIcon, IonLabel, IonItemGroup, IonRow } from '@ionic/react';
import { useRef } from 'react';
import Booking from '../../types/booking.type';
import { singularTitle } from '../../configs/reservation.crud.config';
import Form from './Form';
import Delete from './Delete';
import VehicleType from '../../types/vehicle.type';
import { closeCircleOutline } from 'ionicons/icons';
import { replaceArray } from '../../functions/string.functions';
type Props = {
  vehicleTypes: VehicleType[]
  index: number
  items: Booking[]
  setItems: (item: Booking[]) => void
}
const Edit: React.FC<Props> = ({ index, vehicleTypes, items, setItems }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const item = items[index]
  function onUpdate(item: Booking) {
    // console.log(item)
    items[index] = item
    setItems([...items])
    modal.current?.dismiss()
  }
  function onDelete() {
    items.splice(index, 1)
    setItems([...items])
    modal.current?.dismiss()
  }
  return (
    <>
      <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3' onClick={() => modal.current?.present()}>
        <IonItem>
          <div className='head' slot='start'>
            <h4>{item.identifier}</h4>
            <div><IonText>{new Date(Date.parse(item.start_at)).toLocaleDateString()}</IonText></div>
            <IonText className='price' color="tertiary">$ {item.customer_invoice_total}</IonText>
            <div><IonText className='status'>{item.status.replace("Payment Due", "Due")
              .replace("Driver Offer Sent", "DOS")
              .replace('Driver On The Way', 'DOW')}</IonText></div>

          </div>
          <div>
            <h6>{item.type}</h6>
            <h5>{item.customer?.f_name} {item.customer?.l_name}</h5>
            <div><IonText>{item.customer?.email}</IonText></div>
            <IonText>{item.customer?.phone}</IonText>
          </div>
        </IonItem>
      </IonCol>
      <IonModal ref={modal} className='full-height half-width align-end'>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Edit {singularTitle} #{item.identifier}</IonTitle>
            <IonButton slot='end' onClick={() => modal.current?.dismiss()} fill='clear' color='danger'><IonIcon icon={closeCircleOutline} /></IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
          <Form vehicleTypes={vehicleTypes} item={item} onSave={onUpdate} />
          <Delete item={item} onDelete={onDelete} />
          {(item.customer_paid !== 0 || item.driver_paid !== 0) && <section>
            <IonItem color='dark' style={{ marginTop: 20 }}>
              <IonLabel>Payment & Payout Details</IonLabel>
            </IonItem>
            <IonItemGroup className='invoice-edit'>
              <IonItem color='light'>
                <IonRow>
                  <IonCol size='6'>
                    Total Payment Received
                  </IonCol>
                  <IonCol size='6'>
                    £{item.customer_paid}
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonItem color='light'>
                <IonRow>
                  <IonCol size='6'>
                    Total Payment Given
                  </IonCol>
                  <IonCol size='6'>
                    £{item.driver_paid}
                  </IonCol>
                </IonRow>
              </IonItem>
              <IonItem color='light' style={{ borderTop: "2px solid var(--ion-color-dark)" }}>
                <IonRow>
                  <IonCol size='6'>
                    Total Profit
                  </IonCol>
                  <IonCol size='6'>
                    £{(item.customer_paid - item.driver_paid).toFixed(2)}
                  </IonCol>
                </IonRow>
              </IonItem>
            </IonItemGroup>
          </section>}
        </IonContent>
      </IonModal>
    </>
  );
};

export default Edit;
