import Airline from "../types/airline.type"

const airlines = [
    {
      "name": "Air Kiribati",
      "iata": "4A"
    },
    {
      "name": "Aires",
      "iata": "4C"
    },
    {
      "name": "Air Sinai",
      "iata": "4D"
    },
    {
      "name": "Gazpromavia",
      "iata": "4G"
    },
    {
      "name": "United Airways",
      "iata": "4H"
    },
    {
      "name": "Askari Aviation",
      "iata": "4K"
    },
    {
      "name": "Euroline",
      "iata": "4L"
    },
    {
      "name": "LAN Argentina",
      "iata": "4M"
    },
    {
      "name": "Air North Charter - Canada",
      "iata": "4N"
    },
    {
      "name": "Interjet (ABC Aerolineas)",
      "iata": "4O"
    },
    {
      "name": "Business Aviation",
      "iata": "4P"
    },
    {
      "name": "Safi Airlines",
      "iata": "4Q"
    },
    {
      "name": "Hamburg International",
      "iata": "4R"
    },
    {
      "name": "Belair Airlines",
      "iata": "4T"
    },
    {
      "name": "Germanwings",
      "iata": "4U"
    },
    {
      "name": "Red Jet Mexico",
      "iata": "4X"
    },
    {
      "name": "Airlink (SAA)",
      "iata": "4Z"
    },
    {
      "name": "CAL Cargo Air Lines",
      "iata": "5C"
    },
    {
      "name": "DonbassAero",
      "iata": "5D"
    },
    {
      "name": "Aerolitoral",
      "iata": "5D"
    },
    {
      "name": "SGA Airlines",
      "iata": "5E"
    },
    {
      "name": "Skyservice Airlines",
      "iata": "5G"
    },
    {
      "name": "Fly540",
      "iata": "5H"
    },
    {
      "name": "Cebu Pacific",
      "iata": "5J"
    },
    {
      "name": "Hi Fly (5K)",
      "iata": "5K"
    },
    {
      "name": "Aerosur",
      "iata": "5L"
    },
    {
      "name": "Sibaviatrans",
      "iata": "5M"
    },
    {
      "name": "Aeroflot-Nord",
      "iata": "5N"
    },
    {
      "name": "Pal airlines",
      "iata": "5P"
    },
    {
      "name": "BQB Lineas Aereas",
      "iata": "5Q"
    },
    {
      "name": "Canadian North",
      "iata": "5T"
    },
    {
      "name": "Astraeus",
      "iata": "5W"
    },
    {
      "name": "Atlas Air",
      "iata": "5Y"
    },
    {
      "name": "VivaColombia",
      "iata": "5Z"
    },
    {
      "name": "Consorcio Aviaxsa",
      "iata": "6A"
    },
    {
      "name": "TUIfly Nordic",
      "iata": "6B"
    },
    {
      "name": "Vuela Cuba",
      "iata": "6C"
    },
    {
      "name": "Pelita",
      "iata": "6D"
    },
    {
      "name": "IndiGo Airlines",
      "iata": "6E"
    },
    {
      "name": "MAT Airways",
      "iata": "6F"
    },
    {
      "name": "Air Wales",
      "iata": "6G"
    },
    {
      "name": "Israir",
      "iata": "6H"
    },
    {
      "name": "Alsie Express",
      "iata": "6I"
    },
    {
      "name": "Skynet Asia Airways",
      "iata": "6J"
    },
    {
      "name": "Asian Spirit",
      "iata": "6K"
    },
    {
      "name": "Gryphon Airlines",
      "iata": "6P"
    },
    {
      "name": "Alrosa Mirny Air Enterprise",
      "iata": "6R"
    },
    {
      "name": "Air Mandalay",
      "iata": "6T"
    },
    {
      "name": "Air Cargo Germany",
      "iata": "6U"
    },
    {
      "name": "Saratov Aviation Division",
      "iata": "6W"
    },
    {
      "name": "SmartLynx Airlines",
      "iata": "6Y"
    },
    {
      "name": "Krasnojarsky Airlines",
      "iata": "7B"
    },
    {
      "name": "Jeju Air",
      "iata": "7C"
    },
    {
      "name": "Aeroline GmbH",
      "iata": "7E"
    },
    {
      "name": "First Air",
      "iata": "7F"
    },
    {
      "name": "Star Flyer",
      "iata": "7G"
    },
    {
      "name": "Era Alaska",
      "iata": "7H"
    },
    {
      "name": "Insel Air (7I/INC) (Priv)",
      "iata": "7I"
    },
    {
      "name": "Tajik Air",
      "iata": "7J"
    },
    {
      "name": "Kogalymavia Air Company",
      "iata": "7K"
    },
    {
      "name": "Sun D'Or",
      "iata": "7L"
    },
    {
      "name": "Mongolian International Air Lines ",
      "iata": "7M"
    },
    {
      "name": "All Colombia",
      "iata": "7O"
    },
    {
      "name": "Metro Batavia",
      "iata": "7P"
    },
    {
      "name": "Pan Am World Airways Dominicana",
      "iata": "7Q"
    },
    {
      "name": "Air Glaciers",
      "iata": "7T"
    },
    {
      "name": "Wayraper",
      "iata": "7W"
    },
    {
      "name": "Med Airways",
      "iata": "7Y"
    },
    {
      "name": "Halcyonair",
      "iata": "7Z"
    },
    {
      "name": "Atlas Blue",
      "iata": "8A"
    },
    {
      "name": "BusinessAir",
      "iata": "8B"
    },
    {
      "name": "Astair",
      "iata": "8D"
    },
    {
      "name": "Bering Air",
      "iata": "8E"
    },
    {
      "name": "STP Airways",
      "iata": "8F"
    },
    {
      "name": "Heli France",
      "iata": "8H"
    },
    {
      "name": "Myway Airlines",
      "iata": "8I"
    },
    {
      "name": "Jet4You",
      "iata": "8J"
    },
    {
      "name": "Voestar",
      "iata": "8K"
    },
    {
      "name": "Cargo Plus Aviation",
      "iata": "8L"
    },
    {
      "name": "Myanmar Airways International",
      "iata": "8M"
    },
    {
      "name": "Myanmar Airways International",
      "iata": "8M"
    },
    {
      "name": "Maxair",
      "iata": "8M"
    },
    {
      "name": "Barents AirLink",
      "iata": "8N"
    },
    {
      "name": "West Coast Air",
      "iata": "8O"
    },
    {
      "name": "Pacific Coastal Airline",
      "iata": "8P"
    },
    {
      "name": "Maldivian Air Taxi",
      "iata": "8Q"
    },
    {
      "name": "Onur Air",
      "iata": "8Q"
    },
    {
      "name": "TRIP Linhas A",
      "iata": "8R"
    },
    {
      "name": "Air Tindi",
      "iata": "8T"
    },
    {
      "name": "Afriqiyah Airways",
      "iata": "8U"
    },
    {
      "name": "Astral Aviation",
      "iata": "8V"
    },
    {
      "name": "Wizz Air Hungary",
      "iata": "8Z"
    },
    {
      "name": "All Africa",
      "iata": "9A"
    },
    {
      "name": "China SSS",
      "iata": "9C"
    },
    {
      "name": "Pinnacle Airlines",
      "iata": "9E"
    },
    {
      "name": "MDLR Airlines",
      "iata": "9H"
    },
    {
      "name": "Regionalia Chile",
      "iata": "9J"
    },
    {
      "name": "Cape Air",
      "iata": "9K"
    },
    {
      "name": "Colgan Air",
      "iata": "9L"
    },
    {
      "name": "Regional Air Iceland",
      "iata": "9N"
    },
    {
      "name": "PB Air",
      "iata": "9Q"
    },
    {
      "name": "SATENA",
      "iata": "9R"
    },
    {
      "name": "Spring Airlines",
      "iata": "9S"
    },
    {
      "name": "Transwest Air",
      "iata": "9T"
    },
    {
      "name": "Air Moldova",
      "iata": "9U"
    },
    {
      "name": "Jet Airways",
      "iata": "9W"
    },
    {
      "name": "Regionalia Venezuela",
      "iata": "9X"
    },
    {
      "name": "Air Kazakhstan",
      "iata": "9Y"
    },
    {
      "name": "TUIfly (X3)",
      "iata": "11"
    },
    {
      "name": "12 North",
      "iata": "12"
    },
    {
      "name": "Eastern Atlantic Virtual Airlines",
      "iata": "13"
    },
    {
      "name": "Air Salone",
      "iata": "20"
    },
    {
      "name": "Euro Jet",
      "iata": "24"
    },
    {
      "name": "88",
      "iata": "47"
    },
    {
      "name": "Royal European Airlines",
      "iata": "69"
    },
    {
      "name": "Southjet",
      "iata": "76"
    },
    {
      "name": "Southjet connect",
      "iata": "77"
    },
    {
      "name": "Southjet cargo",
      "iata": "78"
    },
    {
      "name": "All Australia",
      "iata": "88"
    },
    {
      "name": "Ciao Air",
      "iata": "99"
    },
    {
      "name": "Atifly",
      "iata": "A1"
    },
    {
      "name": "All America",
      "iata": "A2"
    },
    {
      "name": "Aegean Airlines",
      "iata": "A3"
    },
    {
      "name": "Southern Winds Airlines",
      "iata": "A4"
    },
    {
      "name": "Airlinair",
      "iata": "A5"
    },
    {
      "name": "Air Alps Aviation (A6)",
      "iata": "A6"
    },
    {
      "name": "Air Plus Comet",
      "iata": "A7"
    },
    {
      "name": "Georgian Airways",
      "iata": "A9"
    },
    {
      "name": "American Airlines",
      "iata": "AA"
    },
    {
      "name": "Air Berlin",
      "iata": "AB"
    },
    {
      "name": "Air Canada",
      "iata": "AC"
    },
    {
      "name": "Azul",
      "iata": "AD"
    },
    {
      "name": "Mandarin Airlines",
      "iata": "AE"
    },
    {
      "name": "Air France",
      "iata": "AF"
    },
    {
      "name": "Air Algerie",
      "iata": "AH"
    },
    {
      "name": "Air India Limited",
      "iata": "AI"
    },
    {
      "name": "Aero Contractors",
      "iata": "AJ"
    },
    {
      "name": "AirAsia",
      "iata": "AK"
    },
    {
      "name": "Skywalk Airlines",
      "iata": "AL"
    },
    {
      "name": "AeroMéxico",
      "iata": "AM"
    },
    {
      "name": "Ansett Australia",
      "iata": "AN"
    },
    {
      "name": "Avianova (Russia)",
      "iata": "AO"
    },
    {
      "name": "Air One",
      "iata": "AP"
    },
    {
      "name": "Aloha Airlines",
      "iata": "AQ"
    },
    {
      "name": "Aerolineas Argentinas",
      "iata": "AR"
    },
    {
      "name": "Alaska Airlines",
      "iata": "AS"
    },
    {
      "name": "Royal Air Maroc",
      "iata": "AT"
    },
    {
      "name": "Austral Lineas Aereas",
      "iata": "AU"
    },
    {
      "name": "Avianca - Aerovias Nacionales de Colombia",
      "iata": "AV"
    },
    {
      "name": "Asian Wings Airways",
      "iata": "AW"
    },
    {
      "name": "Trans States Airlines",
      "iata": "AX"
    },
    {
      "name": "Finnair",
      "iata": "AY"
    },
    {
      "name": "Alitalia",
      "iata": "AZ"
    },
    {
      "name": "Aws express",
      "iata": "B0"
    },
    {
      "name": "Baltic Air lines",
      "iata": "B1"
    },
    {
      "name": "Belavia Belarusian Airlines",
      "iata": "B2"
    },
    {
      "name": "Bhutan Airlines",
      "iata": "B3"
    },
    {
      "name": "Bellview Airlines",
      "iata": "B3"
    },
    {
      "name": "Flyglobespan",
      "iata": "B4"
    },
    {
      "name": "Flightline",
      "iata": "B5"
    },
    {
      "name": "JetBlue Airways",
      "iata": "B6"
    },
    {
      "name": "Uni Air",
      "iata": "B7"
    },
    {
      "name": "Eritrean Airlines",
      "iata": "B8"
    },
    {
      "name": "Iran Airtour",
      "iata": "B9"
    },
    {
      "name": "Air Bangladesh",
      "iata": "B9"
    },
    {
      "name": "British Airways",
      "iata": "BA"
    },
    {
      "name": "Seaborne Airlines",
      "iata": "BB"
    },
    {
      "name": "Skymark Airlines",
      "iata": "BC"
    },
    {
      "name": "bmi",
      "iata": "BD"
    },
    {
      "name": "Flybe",
      "iata": "BE"
    },
    {
      "name": "Aero-Service",
      "iata": "BF"
    },
    {
      "name": "Biman Bangladesh Airlines",
      "iata": "BG"
    },
    {
      "name": "Hawkair",
      "iata": "BH"
    },
    {
      "name": "Royal Brunei Airlines",
      "iata": "BI"
    },
    {
      "name": "Nouvel Air Tunisie",
      "iata": "BJ"
    },
    {
      "name": "Potomac Air",
      "iata": "BK"
    },
    {
      "name": "Jetstar Pacific",
      "iata": "BL"
    },
    {
      "name": "Air Sicilia",
      "iata": "BM"
    },
    {
      "name": "Horizon Airlines",
      "iata": "BN"
    },
    {
      "name": "Air Botswana",
      "iata": "BP"
    },
    {
      "name": "Buquebus Líneas Aéreas",
      "iata": "BQ"
    },
    {
      "name": "EVA Air",
      "iata": "BR"
    },
    {
      "name": "US-Bangla Airlines",
      "iata": "BS"
    },
    {
      "name": "British International Helicopters",
      "iata": "BS"
    },
    {
      "name": "Air Baltic",
      "iata": "BT"
    },
    {
      "name": "Baikotovitchestrian Airlines ",
      "iata": "BU"
    },
    {
      "name": "Blue Panorama Airlines",
      "iata": "BV"
    },
    {
      "name": "Caribbean Airlines",
      "iata": "BW"
    },
    {
      "name": "Air Busan",
      "iata": "BX"
    },
    {
      "name": "Thomsonfly",
      "iata": "BY"
    },
    {
      "name": "Black Stallion Airways",
      "iata": "BZ"
    },
    {
      "name": "Centralwings",
      "iata": "C0"
    },
    {
      "name": "CanXpress",
      "iata": "C1"
    },
    {
      "name": "CanXplorer",
      "iata": "C2"
    },
    {
      "name": "QatXpress",
      "iata": "C3"
    },
    {
      "name": "Contact Air",
      "iata": "C3"
    },
    {
      "name": "LionXpress",
      "iata": "C4"
    },
    {
      "name": "CommutAir",
      "iata": "C5"
    },
    {
      "name": "Sky Wing Pacific",
      "iata": "C7"
    },
    {
      "name": "Cirrus Airlines",
      "iata": "C9"
    },
    {
      "name": "Air China",
      "iata": "CA"
    },
    {
      "name": "CCML Airlines",
      "iata": "CB"
    },
    {
      "name": "Macair Airlines",
      "iata": "CC"
    },
    {
      "name": "Air Atlanta Icelandic",
      "iata": "CC"
    },
    {
      "name": "Air India Regional",
      "iata": "CD"
    },
    {
      "name": "Nationwide Airlines",
      "iata": "CE"
    },
    {
      "name": "City Airline",
      "iata": "CF"
    },
    {
      "name": "Airlines PNG",
      "iata": "CG"
    },
    {
      "name": "Bemidji Airlines",
      "iata": "CH"
    },
    {
      "name": "China Airlines",
      "iata": "CI"
    },
    {
      "name": "BA CityFlyer",
      "iata": "CJ"
    },
    {
      "name": "Lufthansa CityLine",
      "iata": "CL"
    },
    {
      "name": "Copa Airlines",
      "iata": "CM"
    },
    {
      "name": "Canadian National Airways",
      "iata": "CN"
    },
    {
      "name": "Continental Express",
      "iata": "CO"
    },
    {
      "name": "Continental Airlines",
      "iata": "CO"
    },
    {
      "name": "Compass Airlines",
      "iata": "CP"
    },
    {
      "name": "Canadian Airlines",
      "iata": "CP"
    },
    {
      "name": "SOCHI AIR",
      "iata": "CQ"
    },
    {
      "name": "Continental Micronesia",
      "iata": "CS"
    },
    {
      "name": "Alitalia Cityliner",
      "iata": "CT"
    },
    {
      "name": "Cubana de Aviación",
      "iata": "CU"
    },
    {
      "name": "Air Chathams",
      "iata": "CV"
    },
    {
      "name": "Air Marshall Islands",
      "iata": "CW"
    },
    {
      "name": "Cathay Pacific",
      "iata": "CX"
    },
    {
      "name": "Cyprus Airways",
      "iata": "CY"
    },
    {
      "name": "China Southern Airlines",
      "iata": "CZ"
    },
    {
      "name": "Domenican Airlines",
      "iata": "D1"
    },
    {
      "name": "Daallo Airlines",
      "iata": "D3"
    },
    {
      "name": "Interair South Africa",
      "iata": "D6"
    },
    {
      "name": "AirAsia X",
      "iata": "D7"
    },
    {
      "name": "Djibouti Airlines",
      "iata": "D8"
    },
    {
      "name": "Aeroflot-Don",
      "iata": "D9"
    },
    {
      "name": "Dana Air",
      "iata": "DA"
    },
    {
      "name": "Brit Air",
      "iata": "DB"
    },
    {
      "name": "Golden Air",
      "iata": "DC"
    },
    {
      "name": "Nok Air",
      "iata": "DD"
    },
    {
      "name": "Condor Flugdienst",
      "iata": "DE"
    },
    {
      "name": "Michael Airlines",
      "iata": "DF"
    },
    {
      "name": "South East Asian Airlines",
      "iata": "DG"
    },
    {
      "name": "Dennis Sky",
      "iata": "DH"
    },
    {
      "name": "dba",
      "iata": "DI"
    },
    {
      "name": "Eastland Air",
      "iata": "DK"
    },
    {
      "name": "Delta Air Lines",
      "iata": "DL"
    },
    {
      "name": "Maersk",
      "iata": "DM"
    },
    {
      "name": "Senegal Airlines",
      "iata": "DN"
    },
    {
      "name": "Dominicana de Aviaci",
      "iata": "DO"
    },
    {
      "name": "First Choice Airways",
      "iata": "DP"
    },
    {
      "name": "Coastal Air",
      "iata": "DQ"
    },
    {
      "name": "Air Mediterranee",
      "iata": "DR"
    },
    {
      "name": "EasyJet (DS)",
      "iata": "DS"
    },
    {
      "name": "TAAG Angola Airlines",
      "iata": "DT"
    },
    {
      "name": "Scat Air",
      "iata": "DV"
    },
    {
      "name": "DAT Danish Air Transport",
      "iata": "DX"
    },
    {
      "name": "Norwegian Air Shuttle",
      "iata": "DY"
    },
    {
      "name": "Starline.kz",
      "iata": "DZ"
    },
    {
      "name": "Usa Sky Cargo",
      "iata": "E1"
    },
    {
      "name": "Domodedovo Airlines",
      "iata": "E3"
    },
    {
      "name": "Elysian Airlines",
      "iata": "E4"
    },
    {
      "name": "Air Arabia Egypt",
      "iata": "E5"
    },
    {
      "name": "Compagnie Africaine d\\\\'Aviation",
      "iata": "E9"
    },
    {
      "name": "European Air Express",
      "iata": "EA"
    },
    {
      "name": "Avialeasing Aviation Company",
      "iata": "EC"
    },
    {
      "name": "Airblue",
      "iata": "ED"
    },
    {
      "name": "Far Eastern Air Transport",
      "iata": "EF"
    },
    {
      "name": "Japan Asia Airways",
      "iata": "EG"
    },
    {
      "name": "Aer Lingus",
      "iata": "EI"
    },
    {
      "name": "New England Airlines",
      "iata": "EJ"
    },
    {
      "name": "Emirates",
      "iata": "EK"
    },
    {
      "name": "Air Nippon",
      "iata": "EL"
    },
    {
      "name": "Aero Benin",
      "iata": "EM"
    },
    {
      "name": "Air Dolomiti",
      "iata": "EN"
    },
    {
      "name": "Express One International",
      "iata": "EO"
    },
    {
      "name": "Iran Aseman Airlines",
      "iata": "EP"
    },
    {
      "name": "TAME",
      "iata": "EQ"
    },
    {
      "name": "Fly Europa",
      "iata": "ER"
    },
    {
      "name": "EuropeSky",
      "iata": "ES"
    },
    {
      "name": "Ethiopian Airlines",
      "iata": "ET"
    },
    {
      "name": "Empresa Ecuatoriana De Aviacion",
      "iata": "EU"
    },
    {
      "name": "Atlantic Southeast Airlines",
      "iata": "EV"
    },
    {
      "name": "Eurowings",
      "iata": "EW"
    },
    {
      "name": "Etihad Airways",
      "iata": "EY"
    },
    {
      "name": "Evergreen International Airlines",
      "iata": "EZ"
    },
    {
      "name": "Fly Brasil",
      "iata": "F1"
    },
    {
      "name": "Flybaboo",
      "iata": "F7"
    },
    {
      "name": "Air2there",
      "iata": "F8"
    },
    {
      "name": "Frontier Airlines",
      "iata": "F9"
    },
    {
      "name": "Epic Holiday",
      "iata": "FA"
    },
    {
      "name": "Bulgaria Air",
      "iata": "FB"
    },
    {
      "name": "Finncomm Airlines",
      "iata": "FC"
    },
    {
      "name": "Thai AirAsia",
      "iata": "FD"
    },
    {
      "name": "Ariana Afghan Airlines",
      "iata": "FG"
    },
    {
      "name": "FlyHigh Airlines Ireland (FH)",
      "iata": "FH"
    },
    {
      "name": "Icelandair",
      "iata": "FI"
    },
    {
      "name": "Air Pacific",
      "iata": "FJ"
    },
    {
      "name": "Africa West",
      "iata": "FK"
    },
    {
      "name": "AirTran Airways",
      "iata": "FL"
    },
    {
      "name": "Shanghai Airlines",
      "iata": "FM"
    },
    {
      "name": "Regional Airlines",
      "iata": "FN"
    },
    {
      "name": "Airlines Of Tasmania",
      "iata": "FO"
    },
    {
      "name": "Freedom Air",
      "iata": "FP"
    },
    {
      "name": "Thomas Cook Airlines",
      "iata": "FQ"
    },
    {
      "name": "Ryanair",
      "iata": "FR"
    },
    {
      "name": "Servicios de Transportes A",
      "iata": "FS"
    },
    {
      "name": "Siem Reap Airways",
      "iata": "FT"
    },
    {
      "name": "Felix Airways",
      "iata": "FU"
    },
    {
      "name": "Rossiya-Russian Airlines",
      "iata": "FV"
    },
    {
      "name": "Ibex Airlines",
      "iata": "FW"
    },
    {
      "name": "Firefly",
      "iata": "FY"
    },
    {
      "name": "Fly Dubai",
      "iata": "FZ"
    },
    {
      "name": "Ghana International Airlines",
      "iata": "G0"
    },
    {
      "name": "Indya Airline Group",
      "iata": "G1"
    },
    {
      "name": "Sky Express",
      "iata": "G3"
    },
    {
      "name": "Gol Transportes Aéreos",
      "iata": "G3"
    },
    {
      "name": "City Connexion Airlines",
      "iata": "G3"
    },
    {
      "name": "Allegiant Air",
      "iata": "G4"
    },
    {
      "name": "Huaxia",
      "iata": "G5"
    },
    {
      "name": "Air Volga",
      "iata": "G6"
    },
    {
      "name": "GoJet Airlines",
      "iata": "G7"
    },
    {
      "name": "Go Air",
      "iata": "G8"
    },
    {
      "name": "Air Arabia",
      "iata": "G9"
    },
    {
      "name": "Garuda Indonesia",
      "iata": "GA"
    },
    {
      "name": "BRAZIL AIR",
      "iata": "GB"
    },
    {
      "name": "TransAsia Airways",
      "iata": "GE"
    },
    {
      "name": "Gulf Air Bahrain",
      "iata": "GF"
    },
    {
      "name": "Air Guyane",
      "iata": "GG"
    },
    {
      "name": "Globus",
      "iata": "GH"
    },
    {
      "name": "Itek Air",
      "iata": "GI"
    },
    {
      "name": "Eurofly Service",
      "iata": "GJ"
    },
    {
      "name": "Genesis",
      "iata": "GK"
    },
    {
      "name": "Air Greenland",
      "iata": "GL"
    },
    {
      "name": "German International Air Lines",
      "iata": "GM"
    },
    {
      "name": "GNB Linhas Aereas",
      "iata": "GN"
    },
    {
      "name": "Kuzu Airlines Cargo",
      "iata": "GO"
    },
    {
      "name": "Gadair European Airlines",
      "iata": "GP"
    },
    {
      "name": "Aurigny Air Services",
      "iata": "GR"
    },
    {
      "name": "Air Foyle",
      "iata": "GS"
    },
    {
      "name": "GB Airways",
      "iata": "GT"
    },
    {
      "name": "Aero Flight",
      "iata": "GV"
    },
    {
      "name": "Kuban Airlines",
      "iata": "GW"
    },
    {
      "name": "Gabon Airlines",
      "iata": "GY"
    },
    {
      "name": "Air Rarotonga",
      "iata": "GZ"
    },
    {
      "name": "Hankook Air US",
      "iata": "H1"
    },
    {
      "name": "Sky Airline",
      "iata": "H2"
    },
    {
      "name": "Harbour Air (Priv)",
      "iata": "H3"
    },
    {
      "name": "I-Fly",
      "iata": "H5"
    },
    {
      "name": "Hageland Aviation Services",
      "iata": "H6"
    },
    {
      "name": "Eagle Air",
      "iata": "H7"
    },
    {
      "name": "Dalavia",
      "iata": "H8"
    },
    {
      "name": "PEGASUS AIRLINES-",
      "iata": "H9"
    },
    {
      "name": "Hawaiian Airlines",
      "iata": "HA"
    },
    {
      "name": "Himalayan Airlines",
      "iata": "HC"
    },
    {
      "name": "Hokkaido International Airlines",
      "iata": "HD"
    },
    {
      "name": "Luftfahrtgesellschaft Walter",
      "iata": "HE"
    },
    {
      "name": "Hapagfly",
      "iata": "HF"
    },
    {
      "name": "Niki",
      "iata": "HG"
    },
    {
      "name": "Air Hamburg (AHO)",
      "iata": "HH"
    },
    {
      "name": "Papillon Grand Canyon Helicopters",
      "iata": "HI"
    },
    {
      "name": "Yangon Airways",
      "iata": "HK"
    },
    {
      "name": "Air Seychelles",
      "iata": "HM"
    },
    {
      "name": "Hankook Airline",
      "iata": "HN"
    },
    {
      "name": "Juneyao Airlines",
      "iata": "HO"
    },
    {
      "name": "America West Airlines",
      "iata": "HP"
    },
    {
      "name": "China United Airlines",
      "iata": "HR"
    },
    {
      "name": "Hellenic Imperial Airways",
      "iata": "HT"
    },
    {
      "name": "Hainan Airlines",
      "iata": "HU"
    },
    {
      "name": "Transavia Holland",
      "iata": "HV"
    },
    {
      "name": "Hello",
      "iata": "HW"
    },
    {
      "name": "Hong Kong Airlines",
      "iata": "HX"
    },
    {
      "name": "Uzbekistan Airways",
      "iata": "HY"
    },
    {
      "name": "Sat Airlines",
      "iata": "HZ"
    },
    {
      "name": "Iberia Express",
      "iata": "I2"
    },
    {
      "name": "Indonesia Sky",
      "iata": "I5"
    },
    {
      "name": "MexicanaLink",
      "iata": "I6"
    },
    {
      "name": "Paramount Airways",
      "iata": "I7"
    },
    {
      "name": "IzAvia",
      "iata": "I8"
    },
    {
      "name": "Indigo",
      "iata": "I9"
    },
    {
      "name": "Air Italy",
      "iata": "I9"
    },
    {
      "name": "Iraqi Airways",
      "iata": "IA"
    },
    {
      "name": "Iberia Airlines",
      "iata": "IB"
    },
    {
      "name": "Indian Airlines",
      "iata": "IC"
    },
    {
      "name": "Interlink Airlines",
      "iata": "ID"
    },
    {
      "name": "Solomon Airlines",
      "iata": "IE"
    },
    {
      "name": "Islas Airways",
      "iata": "IF"
    },
    {
      "name": "Meridiana",
      "iata": "IG"
    },
    {
      "name": "LSM International ",
      "iata": "II"
    },
    {
      "name": "Imair Airlines",
      "iata": "IK"
    },
    {
      "name": "MAT Macedonian Airlines",
      "iata": "IN"
    },
    {
      "name": "Indonesian Airlines",
      "iata": "IO"
    },
    {
      "name": "Island Spirit",
      "iata": "IP"
    },
    {
      "name": "Augsburg Airways",
      "iata": "IQ"
    },
    {
      "name": "Iran Air",
      "iata": "IR"
    },
    {
      "name": "Island Airlines",
      "iata": "IS"
    },
    {
      "name": "Kingfisher Airlines",
      "iata": "IT"
    },
    {
      "name": "Wind Jet",
      "iata": "IV"
    },
    {
      "name": "Wings Air",
      "iata": "IW"
    },
    {
      "name": "Air India Express",
      "iata": "IX"
    },
    {
      "name": "Yemenia",
      "iata": "IY"
    },
    {
      "name": "Arkia Israel Airlines",
      "iata": "IZ"
    },
    {
      "name": "Azerbaijan Airlines",
      "iata": "J2"
    },
    {
      "name": "Northwestern Air",
      "iata": "J3"
    },
    {
      "name": "ALAK",
      "iata": "J4"
    },
    {
      "name": "Alaska Seaplane Service",
      "iata": "J5"
    },
    {
      "name": "Denim Air ",
      "iata": "J7"
    },
    {
      "name": "Berjaya Air",
      "iata": "J8"
    },
    {
      "name": "Jazeera Airways",
      "iata": "J9"
    },
    {
      "name": "Air Bosna",
      "iata": "JA"
    },
    {
      "name": "Helijet",
      "iata": "JB"
    },
    {
      "name": "JAL Express",
      "iata": "JC"
    },
    {
      "name": "Japan Air System",
      "iata": "JD"
    },
    {
      "name": "Mango",
      "iata": "JE"
    },
    {
      "name": "Jetairfly",
      "iata": "JF"
    },
    {
      "name": "Fuji Dream Airlines",
      "iata": "JH"
    },
    {
      "name": "Midway Airlines",
      "iata": "JI"
    },
    {
      "name": "TAM Brazilian Airlines",
      "iata": "JJ"
    },
    {
      "name": "Spanair",
      "iata": "JK"
    },
    {
      "name": "Japan Airlines Domestic",
      "iata": "JL"
    },
    {
      "name": "Japan Airlines",
      "iata": "JL"
    },
    {
      "name": "Air Jamaica",
      "iata": "JM"
    },
    {
      "name": "Excel Airways",
      "iata": "JN"
    },
    {
      "name": "JALways",
      "iata": "JO"
    },
    {
      "name": "Adria Airways",
      "iata": "JP"
    },
    {
      "name": "Jetstar Airways",
      "iata": "JQ"
    },
    {
      "name": "Joy Air",
      "iata": "JR"
    },
    {
      "name": "Air Koryo",
      "iata": "JS"
    },
    {
      "name": "Lion Mentari Airlines",
      "iata": "JT"
    },
    {
      "name": "Jat Airways",
      "iata": "JU"
    },
    {
      "name": "Bearskin Lake Air Service",
      "iata": "JV"
    },
    {
      "name": "Arrow Air",
      "iata": "JW"
    },
    {
      "name": "Jusur airways",
      "iata": "JX"
    },
    {
      "name": "Aereonautica militare",
      "iata": "JY"
    },
    {
      "name": "Skyways Express",
      "iata": "JZ"
    },
    {
      "name": "Kostromskie avialinii",
      "iata": "K1"
    },
    {
      "name": "Eurolot",
      "iata": "K2"
    },
    {
      "name": "SeaPort Airlines",
      "iata": "K5"
    },
    {
      "name": "Cambodia Angkor Air (K6)",
      "iata": "K6"
    },
    {
      "name": "KoralBlue Airlines",
      "iata": "K7"
    },
    {
      "name": "Zambia Skyways",
      "iata": "K8"
    },
    {
      "name": "Dragonair",
      "iata": "KA"
    },
    {
      "name": "Druk Air",
      "iata": "KB"
    },
    {
      "name": "Air Astana",
      "iata": "KC"
    },
    {
      "name": "KD Avia",
      "iata": "KD"
    },
    {
      "name": "Korean Air",
      "iata": "KE"
    },
    {
      "name": "Blue1",
      "iata": "KF"
    },
    {
      "name": "Royal Airways",
      "iata": "KG"
    },
    {
      "name": "Kharkiv Airlines",
      "iata": "KH"
    },
    {
      "name": "Adam Air",
      "iata": "KI"
    },
    {
      "name": "British Mediterranean Airways",
      "iata": "KJ"
    },
    {
      "name": "Atlasjet",
      "iata": "KK"
    },
    {
      "name": "KLM Royal Dutch Airlines",
      "iata": "KL"
    },
    {
      "name": "Air Malta",
      "iata": "KM"
    },
    {
      "name": "China United",
      "iata": "KN"
    },
    {
      "name": "Alaska Central Express",
      "iata": "KO"
    },
    {
      "name": "Kenya Airways",
      "iata": "KQ"
    },
    {
      "name": "Comores Airlines",
      "iata": "KR"
    },
    {
      "name": "Peninsula Airways",
      "iata": "KS"
    },
    {
      "name": "VickJet",
      "iata": "KT"
    },
    {
      "name": "Kuwait Airways",
      "iata": "KU"
    },
    {
      "name": "Kavminvodyavia",
      "iata": "KV"
    },
    {
      "name": "Carnival Air Lines",
      "iata": "KW"
    },
    {
      "name": "Cayman Airways",
      "iata": "KX"
    },
    {
      "name": "KSY",
      "iata": "KY"
    },
    {
      "name": "All Argentina",
      "iata": "L1"
    },
    {
      "name": "LTU Austria",
      "iata": "L3"
    },
    {
      "name": "Luchsh Airlines ",
      "iata": "L4"
    },
    {
      "name": "Lufttransport",
      "iata": "L5"
    },
    {
      "name": "Mauritania Airlines International",
      "iata": "L6"
    },
    {
      "name": "Lugansk Airlines",
      "iata": "L7"
    },
    {
      "name": "Line Blue",
      "iata": "L8"
    },
    {
      "name": "All Asia",
      "iata": "L9"
    },
    {
      "name": "LAN Airlines",
      "iata": "LA"
    },
    {
      "name": "Air Costa",
      "iata": "LB"
    },
    {
      "name": "Varig Log",
      "iata": "LC"
    },
    {
      "name": "FlyNordic",
      "iata": "LF"
    },
    {
      "name": "Luxair",
      "iata": "LG"
    },
    {
      "name": "Lufthansa Cargo",
      "iata": "LH"
    },
    {
      "name": "Lufthansa",
      "iata": "LH"
    },
    {
      "name": "Leeward Islands Air Transport",
      "iata": "LI"
    },
    {
      "name": "Jin Air",
      "iata": "LJ"
    },
    {
      "name": "Air Luxor",
      "iata": "LK"
    },
    {
      "name": "Linhas A",
      "iata": "LM"
    },
    {
      "name": "Libyan Arab Airlines",
      "iata": "LN"
    },
    {
      "name": "LOT Polish Airlines",
      "iata": "LO"
    },
    {
      "name": "LAN Peru",
      "iata": "LP"
    },
    {
      "name": "LCM AIRLINES",
      "iata": "LQ"
    },
    {
      "name": "LACSA",
      "iata": "LR"
    },
    {
      "name": "Jet2.com",
      "iata": "LS"
    },
    {
      "name": "LTU International",
      "iata": "LT"
    },
    {
      "name": "LAN Express",
      "iata": "LU"
    },
    {
      "name": "Albanian Airlines",
      "iata": "LV"
    },
    {
      "name": "Pacific Wings",
      "iata": "LW"
    },
    {
      "name": "Swiss International Air Lines",
      "iata": "LX"
    },
    {
      "name": "El Al Israel Airlines",
      "iata": "LY"
    },
    {
      "name": "Balkan Bulgarian Airlines",
      "iata": "LZ"
    },
    {
      "name": "Maryland Air",
      "iata": "M1"
    },
    {
      "name": "MHS Aviation GmbH",
      "iata": "M2"
    },
    {
      "name": "ABSA - Aerolinhas Brasileiras",
      "iata": "M3"
    },
    {
      "name": "Marysya Airlines",
      "iata": "M4"
    },
    {
      "name": "Kenmore Air",
      "iata": "M5"
    },
    {
      "name": "MasAir",
      "iata": "M7"
    },
    {
      "name": "Motor Sich",
      "iata": "M9"
    },
    {
      "name": "Malév",
      "iata": "MA"
    },
    {
      "name": "MNG Airlines",
      "iata": "MB"
    },
    {
      "name": "Air Madagascar",
      "iata": "MD"
    },
    {
      "name": "Middle East Airlines",
      "iata": "ME"
    },
    {
      "name": "Xiamen Airlines",
      "iata": "MF"
    },
    {
      "name": "Malaysia Airlines",
      "iata": "MH"
    },
    {
      "name": "SilkAir",
      "iata": "MI"
    },
    {
      "name": "L",
      "iata": "MJ"
    },
    {
      "name": "Air Mauritius",
      "iata": "MK"
    },
    {
      "name": "Maldivo Airlines",
      "iata": "ML"
    },
    {
      "name": "Peach Aviation",
      "iata": "MM"
    },
    {
      "name": "Comair",
      "iata": "MN"
    },
    {
      "name": "Abu Dhabi Amiri Flight",
      "iata": "MO"
    },
    {
      "name": "Martinair",
      "iata": "MP"
    },
    {
      "name": "American Eagle Airlines",
      "iata": "MQ"
    },
    {
      "name": "Homer Air",
      "iata": "MR"
    },
    {
      "name": "Egyptair",
      "iata": "MS"
    },
    {
      "name": "Thomas Cook Airlines",
      "iata": "MT"
    },
    {
      "name": "China Eastern Airlines",
      "iata": "MU"
    },
    {
      "name": "Maya Island Air",
      "iata": "MW"
    },
    {
      "name": "Mexicana de Aviaci",
      "iata": "MX"
    },
    {
      "name": "Midwest Airlines (Egypt)",
      "iata": "MY"
    },
    {
      "name": "Merpati Nusantara Airlines",
      "iata": "MZ"
    },
    {
      "name": "Norte Lineas Aereas",
      "iata": "N0"
    },
    {
      "name": "N1",
      "iata": "N1"
    },
    {
      "name": "Regionalia México",
      "iata": "N4"
    },
    {
      "name": "Skagway Air Service",
      "iata": "N5"
    },
    {
      "name": "All Spain",
      "iata": "N7"
    },
    {
      "name": "National Air Cargo",
      "iata": "N8"
    },
    {
      "name": "All Europe",
      "iata": "N9"
    },
    {
      "name": "Al-Naser Airlines",
      "iata": "NA"
    },
    {
      "name": "Sterling Airlines",
      "iata": "NB"
    },
    {
      "name": "National Jet Systems",
      "iata": "NC"
    },
    {
      "name": "SkyEurope",
      "iata": "NE"
    },
    {
      "name": "Air Vanuatu",
      "iata": "NF"
    },
    {
      "name": "Lauda Air",
      "iata": "NG"
    },
    {
      "name": "All Nippon Airways",
      "iata": "NH"
    },
    {
      "name": "Portugalia",
      "iata": "NI"
    },
    {
      "name": "Nordic Global Airlines",
      "iata": "NJ"
    },
    {
      "name": "Spirit Airlines",
      "iata": "NK"
    },
    {
      "name": "Shaheen Air International",
      "iata": "NL"
    },
    {
      "name": "Air Madrid",
      "iata": "NM"
    },
    {
      "name": "VIM Airlines",
      "iata": "NN"
    },
    {
      "name": "Nile Air",
      "iata": "NP"
    },
    {
      "name": "Air Japan",
      "iata": "NQ"
    },
    {
      "name": "Jettor Airlines",
      "iata": "NR"
    },
    {
      "name": "Caucasus Airlines",
      "iata": "NS"
    },
    {
      "name": "Binter Canarias",
      "iata": "NT"
    },
    {
      "name": "Japan Transocean Air",
      "iata": "NU"
    },
    {
      "name": "Northwest Airlines",
      "iata": "NW"
    },
    {
      "name": "Air Macau",
      "iata": "NX"
    },
    {
      "name": "Air Iceland",
      "iata": "NY"
    },
    {
      "name": "Air New Zealand",
      "iata": "NZ"
    },
    {
      "name": "Orbit Airlines Azerbaijan",
      "iata": "O1"
    },
    {
      "name": "Oceanic Airlines",
      "iata": "O2"
    },
    {
      "name": "SF Airlines",
      "iata": "O3"
    },
    {
      "name": "Oceanair",
      "iata": "O6"
    },
    {
      "name": "Ozjet Airlines",
      "iata": "O7"
    },
    {
      "name": "Oasis Hong Kong Airlines",
      "iata": "O8"
    },
    {
      "name": "Olympic Airlines",
      "iata": "OA"
    },
    {
      "name": "Astrakhan Airlines",
      "iata": "OB"
    },
    {
      "name": "Catovair",
      "iata": "OC"
    },
    {
      "name": "Malindo Air",
      "iata": "OD"
    },
    {
      "name": "Air Finland",
      "iata": "OF"
    },
    {
      "name": "AirOnix",
      "iata": "OG"
    },
    {
      "name": "Comair",
      "iata": "OH"
    },
    {
      "name": "Orchid Airlines",
      "iata": "OI"
    },
    {
      "name": "Overland Airways",
      "iata": "OJ"
    },
    {
      "name": "Czech Airlines",
      "iata": "OK"
    },
    {
      "name": "Ostfriesische Lufttransport",
      "iata": "OL"
    },
    {
      "name": "MIAT Mongolian Airlines",
      "iata": "OM"
    },
    {
      "name": "Nauru Air Corporation",
      "iata": "ON"
    },
    {
      "name": "SkyWest",
      "iata": "OO"
    },
    {
      "name": "Chongqing Airlines",
      "iata": "OQ"
    },
    {
      "name": "Arkefly",
      "iata": "OR"
    },
    {
      "name": "Austrian Airlines",
      "iata": "OS"
    },
    {
      "name": "Aeropelican Air Services",
      "iata": "OT"
    },
    {
      "name": "Croatia Airlines",
      "iata": "OU"
    },
    {
      "name": "Estonian Air",
      "iata": "OV"
    },
    {
      "name": "Orient Thai Airlines",
      "iata": "OX"
    },
    {
      "name": "Omni Air International",
      "iata": "OY"
    },
    {
      "name": "Asiana Airlines",
      "iata": "OZ"
    },
    {
      "name": "Proflight Commuter Services",
      "iata": "P0"
    },
    {
      "name": "Patriot Airways",
      "iata": "P4"
    },
    {
      "name": "AeroRep",
      "iata": "P5"
    },
    {
      "name": "Regional Paraguaya",
      "iata": "P7"
    },
    {
      "name": "Air Mekong",
      "iata": "P8"
    },
    {
      "name": "Peruvian Airlines",
      "iata": "P9"
    },
    {
      "name": "Parmiss Airlines (IPV)",
      "iata": "PA"
    },
    {
      "name": "Pegasus Airlines",
      "iata": "PC"
    },
    {
      "name": "Porter Airlines",
      "iata": "PD"
    },
    {
      "name": "Air Europe",
      "iata": "PE"
    },
    {
      "name": "Primera Air",
      "iata": "PF"
    },
    {
      "name": "Bangkok Airways",
      "iata": "PG"
    },
    {
      "name": "Polynesian Airlines",
      "iata": "PH"
    },
    {
      "name": "Piedmont Airlines (1948-1989)",
      "iata": "PI"
    },
    {
      "name": "Air Saint Pierre",
      "iata": "PJ"
    },
    {
      "name": "Pakistan International Airlines",
      "iata": "PK"
    },
    {
      "name": "Aeroper",
      "iata": "PL"
    },
    {
      "name": "Tropic Air",
      "iata": "PM"
    },
    {
      "name": "West Air China",
      "iata": "PN"
    },
    {
      "name": "FlyPortugal",
      "iata": "PO"
    },
    {
      "name": "Air Indus",
      "iata": "PP"
    },
    {
      "name": "LSM Airlines",
      "iata": "PQ"
    },
    {
      "name": "Philippine Airlines",
      "iata": "PR"
    },
    {
      "name": "Ukraine International Airlines",
      "iata": "PS"
    },
    {
      "name": "Red Jet Andes",
      "iata": "PT"
    },
    {
      "name": "PLUNA",
      "iata": "PU"
    },
    {
      "name": "PAN Air",
      "iata": "PV"
    },
    {
      "name": "Precision Air",
      "iata": "PW"
    },
    {
      "name": "Air Niugini",
      "iata": "PX"
    },
    {
      "name": "Surinam Airways",
      "iata": "PY"
    },
    {
      "name": "TAM Mercosur",
      "iata": "PZ"
    },
    {
      "name": "Maldivian",
      "iata": "Q2"
    },
    {
      "name": "SOCHI AIR CHATER",
      "iata": "Q3"
    },
    {
      "name": "40-Mile Air",
      "iata": "Q5"
    },
    {
      "name": "Aero Condor Peru",
      "iata": "Q6"
    },
    {
      "name": "Pacific East Asia Cargo Airlines",
      "iata": "Q8"
    },
    {
      "name": "Arik Niger",
      "iata": "Q9"
    },
    {
      "name": "Click (Mexicana)",
      "iata": "QA"
    },
    {
      "name": "Georgian National Airlines",
      "iata": "QB"
    },
    {
      "name": "Camair-co",
      "iata": "QC"
    },
    {
      "name": "Dobrolet",
      "iata": "QD"
    },
    {
      "name": "Qatar Executive",
      "iata": "QE"
    },
    {
      "name": "Qantas",
      "iata": "QF"
    },
    {
      "name": "Citilink Indonesia",
      "iata": "QG"
    },
    {
      "name": "Air Florida",
      "iata": "QH"
    },
    {
      "name": "Cimber Air",
      "iata": "QI"
    },
    {
      "name": "Jet Airways",
      "iata": "QJ"
    },
    {
      "name": "Air Canada Jazz",
      "iata": "QK"
    },
    {
      "name": "Aero Lanka",
      "iata": "QL"
    },
    {
      "name": "Air Malawi",
      "iata": "QM"
    },
    {
      "name": "Origin Pacific Airways",
      "iata": "QO"
    },
    {
      "name": "Alliance Airlines",
      "iata": "QQ"
    },
    {
      "name": "Qatar Airways",
      "iata": "QR"
    },
    {
      "name": "Travel Service",
      "iata": "QS"
    },
    {
      "name": "TAMPA",
      "iata": "QT"
    },
    {
      "name": "East African",
      "iata": "QU"
    },
    {
      "name": "Lao Airlines",
      "iata": "QV"
    },
    {
      "name": "Blue Wings",
      "iata": "QW"
    },
    {
      "name": "Horizon Air",
      "iata": "QX"
    },
    {
      "name": "Red Jet Canada",
      "iata": "QY"
    },
    {
      "name": "Indonesia AirAsia",
      "iata": "QZ"
    },
    {
      "name": "Orenburg Airlines",
      "iata": "R2"
    },
    {
      "name": "Aircompany Yakutia",
      "iata": "R3"
    },
    {
      "name": "Rossiya",
      "iata": "R4"
    },
    {
      "name": "Malta Air Charter",
      "iata": "R5"
    },
    {
      "name": "RACSA",
      "iata": "R6"
    },
    {
      "name": "Aserca Airlines",
      "iata": "R7"
    },
    {
      "name": "AirRussia",
      "iata": "R8"
    },
    {
      "name": "Royal Nepal Airlines",
      "iata": "RA"
    },
    {
      "name": "Nepal Airlines",
      "iata": "RA"
    },
    {
      "name": "Syrian Arab Airlines",
      "iata": "RB"
    },
    {
      "name": "Atlantic Airways",
      "iata": "RC"
    },
    {
      "name": "Ryan International Airlines",
      "iata": "RD"
    },
    {
      "name": "Aer Arann",
      "iata": "RE"
    },
    {
      "name": "Florida West International Airways",
      "iata": "RF"
    },
    {
      "name": "VRG Linhas Aereas",
      "iata": "RG"
    },
    {
      "name": "Hong Kong Air Cargo",
      "iata": "RH"
    },
    {
      "name": "Republic Express Airlines",
      "iata": "RH"
    },
    {
      "name": "Mandala Airlines",
      "iata": "RI"
    },
    {
      "name": "Royal Jordanian",
      "iata": "RJ"
    },
    {
      "name": "Air Afrique",
      "iata": "RK"
    },
    {
      "name": "Royal Falcon",
      "iata": "RL"
    },
    {
      "name": "Rainbow Air US",
      "iata": "RM"
    },
    {
      "name": "Rainbow Air (RAI)",
      "iata": "RN"
    },
    {
      "name": "Tarom",
      "iata": "RO"
    },
    {
      "name": "Chautauqua Airlines",
      "iata": "RP"
    },
    {
      "name": "Kam Air",
      "iata": "RQ"
    },
    {
      "name": "Sky Regional",
      "iata": "RS"
    },
    {
      "name": "Rainbow Air Euro",
      "iata": "RU"
    },
    {
      "name": "Caspian Airlines",
      "iata": "RV"
    },
    {
      "name": "Republic Airlines",
      "iata": "RW"
    },
    {
      "name": "Regent Airways",
      "iata": "RX"
    },
    {
      "name": "Rainbow Air Polynesia",
      "iata": "RX"
    },
    {
      "name": "Rainbow Air Canada",
      "iata": "RY"
    },
    {
      "name": "Euro Exec Express",
      "iata": "RZ"
    },
    {
      "name": "Spike Airlines",
      "iata": "S0"
    },
    {
      "name": "Serbian Airlines",
      "iata": "S1"
    },
    {
      "name": "Air Sahara",
      "iata": "S2"
    },
    {
      "name": "Santa Barbara Airlines",
      "iata": "S3"
    },
    {
      "name": "SATA International",
      "iata": "S4"
    },
    {
      "name": "Shuttle America",
      "iata": "S5"
    },
    {
      "name": "Salmon Air",
      "iata": "S6"
    },
    {
      "name": "S7 Airlines",
      "iata": "S7"
    },
    {
      "name": "Snowbird Airlines",
      "iata": "S8"
    },
    {
      "name": "Starbow Airlines",
      "iata": "S9"
    },
    {
      "name": "South African Airways",
      "iata": "SA"
    },
    {
      "name": "Air Caledonie International",
      "iata": "SB"
    },
    {
      "name": "Shandong Airlines",
      "iata": "SC"
    },
    {
      "name": "Sudan Airways",
      "iata": "SD"
    },
    {
      "name": "XL Airways France",
      "iata": "SE"
    },
    {
      "name": "Spicejet",
      "iata": "SG"
    },
    {
      "name": "Sharp Airlines",
      "iata": "SH"
    },
    {
      "name": "Skynet Airlines",
      "iata": "SI"
    },
    {
      "name": "Sriwijaya Air",
      "iata": "SJ"
    },
    {
      "name": "Scandinavian Airlines System",
      "iata": "SK"
    },
    {
      "name": "Thai Lion Air",
      "iata": "SL"
    },
    {
      "name": "Spirit of Manila Airlines",
      "iata": "SM"
    },
    {
      "name": "Brussels Airlines",
      "iata": "SN"
    },
    {
      "name": "Salsa d\\\\'Haiti",
      "iata": "SO"
    },
    {
      "name": "SATA Air Acores",
      "iata": "SP"
    },
    {
      "name": "Singapore Airlines Cargo",
      "iata": "SQ"
    },
    {
      "name": "Singapore Airlines",
      "iata": "SQ"
    },
    {
      "name": "Swissair",
      "iata": "SR"
    },
    {
      "name": "Corsairfly",
      "iata": "SS"
    },
    {
      "name": "Germania",
      "iata": "ST"
    },
    {
      "name": "Aeroflot Russian Airlines",
      "iata": "SU"
    },
    {
      "name": "Saudi Arabian Airlines",
      "iata": "SV"
    },
    {
      "name": "Air Namibia",
      "iata": "SW"
    },
    {
      "name": "SkyWork Airlines ",
      "iata": "SX"
    },
    {
      "name": "Sun Country Airlines",
      "iata": "SY"
    },
    {
      "name": "Salzburg arrows",
      "iata": "SZ"
    },
    {
      "name": "TACA Peru",
      "iata": "T0"
    },
    {
      "name": "Thai Air Cargo",
      "iata": "T2"
    },
    {
      "name": "Eastern Airways",
      "iata": "T3"
    },
    {
      "name": "Hellas Jet",
      "iata": "T4"
    },
    {
      "name": "Turkmenistan Airlines",
      "iata": "T5"
    },
    {
      "name": "Trans Pas Air",
      "iata": "T6"
    },
    {
      "name": "Twin Jet",
      "iata": "T7"
    },
    {
      "name": "Grupo TACA",
      "iata": "TA"
    },
    {
      "name": "TrasBrasil",
      "iata": "TB"
    },
    {
      "name": "Air Tanzania",
      "iata": "TC"
    },
    {
      "name": "Atlantis European Airways",
      "iata": "TD"
    },
    {
      "name": "FlyLal",
      "iata": "TE"
    },
    {
      "name": "Malmö Aviation",
      "iata": "TF"
    },
    {
      "name": "Thai Airways International",
      "iata": "TG"
    },
    {
      "name": "TransBrasil Airlines",
      "iata": "TH"
    },
    {
      "name": "TransHolding",
      "iata": "TI"
    },
    {
      "name": "T.J. Air",
      "iata": "TJ"
    },
    {
      "name": "Turkish Airlines",
      "iata": "TK"
    },
    {
      "name": "Trans Mediterranean Airlines",
      "iata": "TL"
    },
    {
      "name": "Airnorth",
      "iata": "TL"
    },
    {
      "name": "Air Mozambique",
      "iata": "TM"
    },
    {
      "name": "Air Tahiti Nui",
      "iata": "TN"
    },
    {
      "name": "Transavia France",
      "iata": "TO"
    },
    {
      "name": "TAP Portugal",
      "iata": "TP"
    },
    {
      "name": "Texas Wings",
      "iata": "TQ"
    },
    {
      "name": "Scoot",
      "iata": "TR"
    },
    {
      "name": "Tiger Airways",
      "iata": "TR"
    },
    {
      "name": "Air Transat",
      "iata": "TS"
    },
    {
      "name": "Tigerair Australia",
      "iata": "TT"
    },
    {
      "name": "Tunisair",
      "iata": "TU"
    },
    {
      "name": "Virgin Express",
      "iata": "TV"
    },
    {
      "name": "Tway Airlines",
      "iata": "TW"
    },
    {
      "name": "Air Caraïbes",
      "iata": "TX"
    },
    {
      "name": "Iberworld",
      "iata": "TY"
    },
    {
      "name": "Aviabus",
      "iata": "U1"
    },
    {
      "name": "easyJet",
      "iata": "U2"
    },
    {
      "name": "Avies",
      "iata": "U3"
    },
    {
      "name": "PMTair",
      "iata": "U4"
    },
    {
      "name": "USA3000 Airlines",
      "iata": "U5"
    },
    {
      "name": "Ural Airlines",
      "iata": "U6"
    },
    {
      "name": "Northern Dene Airways",
      "iata": "U7"
    },
    {
      "name": "Armavia",
      "iata": "U8"
    },
    {
      "name": "Tatarstan Airlines",
      "iata": "U9"
    },
    {
      "name": "United Airlines",
      "iata": "UA"
    },
    {
      "name": "Myanma Airways",
      "iata": "UB"
    },
    {
      "name": "Hex'Air",
      "iata": "UD"
    },
    {
      "name": "Nasair",
      "iata": "UE"
    },
    {
      "name": "UM Airlines",
      "iata": "UF"
    },
    {
      "name": "Tuninter",
      "iata": "UG"
    },
    {
      "name": "US Helicopter Corporation",
      "iata": "UH"
    },
    {
      "name": "Eurocypria Airlines",
      "iata": "UI"
    },
    {
      "name": "AlMasria Universal Airlines",
      "iata": "UJ"
    },
    {
      "name": "Vistera",
      "iata": "UK"
    },
    {
      "name": "SriLankan Airlines",
      "iata": "UL"
    },
    {
      "name": "Air Zimbabwe",
      "iata": "UM"
    },
    {
      "name": "Transaero Airlines",
      "iata": "UN"
    },
    {
      "name": "Hong Kong Express Airways",
      "iata": "UO"
    },
    {
      "name": "Bahamasair",
      "iata": "UP"
    },
    {
      "name": "Guangxi Beibu Gulf Airlines",
      "iata": "UQ"
    },
    {
      "name": "Urumqi Air",
      "iata": "UQ"
    },
    {
      "name": "Skyjet Airlines",
      "iata": "UQ"
    },
    {
      "name": "UTair-Express",
      "iata": "UR"
    },
    {
      "name": "US Airways",
      "iata": "US"
    },
    {
      "name": "UTair Aviation",
      "iata": "UT"
    },
    {
      "name": "Air Austral",
      "iata": "UU"
    },
    {
      "name": "Air Europa",
      "iata": "UX"
    },
    {
      "name": "El-Buraq Air Transport",
      "iata": "UZ"
    },
    {
      "name": "Conviasa",
      "iata": "V0"
    },
    {
      "name": "Vision Airlines (V2)",
      "iata": "V2"
    },
    {
      "name": "Carpatair",
      "iata": "V3"
    },
    {
      "name": "Danube Wings (V5)",
      "iata": "V5"
    },
    {
      "name": "VIP Ecuador",
      "iata": "V6"
    },
    {
      "name": "ATRAN Cargo Airlines",
      "iata": "V8"
    },
    {
      "name": "Star1 Airlines",
      "iata": "V9"
    },
    {
      "name": "Virgin Australia",
      "iata": "VA"
    },
    {
      "name": "Strategic Airlines",
      "iata": "VC"
    },
    {
      "name": "Air Libert",
      "iata": "VD"
    },
    {
      "name": "Volare Airlines",
      "iata": "VE"
    },
    {
      "name": "Valuair",
      "iata": "VF"
    },
    {
      "name": "VLM Airlines",
      "iata": "VG"
    },
    {
      "name": "Virgin Pacific",
      "iata": "VH"
    },
    {
      "name": "Volga-Dnepr Airlines",
      "iata": "VI"
    },
    {
      "name": "Royal Air Cambodge",
      "iata": "VJ"
    },
    {
      "name": "Virgin Nigeria Airways",
      "iata": "VK"
    },
    {
      "name": "Air VIA",
      "iata": "VL"
    },
    {
      "name": "Vietnam Airlines",
      "iata": "VN"
    },
    {
      "name": "Tyrolean Airways",
      "iata": "VO"
    },
    {
      "name": "VASP",
      "iata": "VP"
    },
    {
      "name": "Viking Hellas",
      "iata": "VQ"
    },
    {
      "name": "TACV",
      "iata": "VR"
    },
    {
      "name": "Virgin Atlantic Airways",
      "iata": "VS"
    },
    {
      "name": "Air Tahiti",
      "iata": "VT"
    },
    {
      "name": "Air Ivoire",
      "iata": "VU"
    },
    {
      "name": "Aerosvit Airlines",
      "iata": "VV"
    },
    {
      "name": "Aeromar",
      "iata": "VW"
    },
    {
      "name": "Virgin America",
      "iata": "VX"
    },
    {
      "name": "Vueling Airlines",
      "iata": "VY"
    },
    {
      "name": "Formosa Airlines",
      "iata": "VY"
    },
    {
      "name": "MyTravel Airways",
      "iata": "VZ"
    },
    {
      "name": "World Experience Airline",
      "iata": "W1"
    },
    {
      "name": "Maastricht Airlines",
      "iata": "W2"
    },
    {
      "name": "AeroWorld ",
      "iata": "W4"
    },
    {
      "name": "Mahan Air",
      "iata": "W5"
    },
    {
      "name": "Wizz Air",
      "iata": "W6"
    },
    {
      "name": "Austral Brasil",
      "iata": "W7"
    },
    {
      "name": "Air Bagan",
      "iata": "W9"
    },
    {
      "name": "Western Airlines",
      "iata": "WA"
    },
    {
      "name": "KLM Cityhopper",
      "iata": "WA"
    },
    {
      "name": "Rwandair Express",
      "iata": "WB"
    },
    {
      "name": "Islena De Inversiones",
      "iata": "WC"
    },
    {
      "name": "Amsterdam Airlines",
      "iata": "WD"
    },
    {
      "name": "Widerøe",
      "iata": "WF"
    },
    {
      "name": "Sunwing",
      "iata": "WG"
    },
    {
      "name": "China Northwest Airlines (WH)",
      "iata": "WH"
    },
    {
      "name": "WebJet Linhas A",
      "iata": "WJ"
    },
    {
      "name": "Edelweiss Air",
      "iata": "WK"
    },
    {
      "name": "Windward Islands Airways",
      "iata": "WM"
    },
    {
      "name": "Southwest Airlines",
      "iata": "WN"
    },
    {
      "name": "World Airways",
      "iata": "WO"
    },
    {
      "name": "Island Air (WP)",
      "iata": "WP"
    },
    {
      "name": "PanAm World Airways",
      "iata": "WQ"
    },
    {
      "name": "WestJet",
      "iata": "WS"
    },
    {
      "name": "Wizz Air Ukraine",
      "iata": "WU"
    },
    {
      "name": "Swe Fly",
      "iata": "WV"
    },
    {
      "name": "bmibaby",
      "iata": "WW"
    },
    {
      "name": "CityJet",
      "iata": "WX"
    },
    {
      "name": "Oman Air",
      "iata": "WY"
    },
    {
      "name": "Red Wings",
      "iata": "WZ"
    },
    {
      "name": "TUIfly",
      "iata": "X3"
    },
    {
      "name": "XAIR USA",
      "iata": "XA"
    },
    {
      "name": "NEXT Brasil",
      "iata": "XB"
    },
    {
      "name": "ExpressJet",
      "iata": "XE"
    },
    {
      "name": "Vladivostok Air",
      "iata": "XF"
    },
    {
      "name": "Calima Aviacion",
      "iata": "XG"
    },
    {
      "name": "Mesaba Airlines",
      "iata": "XJ"
    },
    {
      "name": "Corse-Mediterranee",
      "iata": "XK"
    },
    {
      "name": "Aerolane",
      "iata": "XL"
    },
    {
      "name": "Alitalia Express",
      "iata": "XM"
    },
    {
      "name": "Xpressair",
      "iata": "XN"
    },
    {
      "name": "LTE International Airways",
      "iata": "XO"
    },
    {
      "name": "XPTO",
      "iata": "XP"
    },
    {
      "name": "SunExpress",
      "iata": "XQ"
    },
    {
      "name": "Skywest Australia",
      "iata": "XR"
    },
    {
      "name": "Indonesia AirAsia X",
      "iata": "XT"
    },
    {
      "name": "Air Exel",
      "iata": "XT"
    },
    {
      "name": "BVI Airways",
      "iata": "XV"
    },
    {
      "name": "Sky Express",
      "iata": "XW"
    },
    {
      "name": "Greenfly",
      "iata": "XX"
    },
    {
      "name": "Nas Air",
      "iata": "XY"
    },
    {
      "name": "Congo Express",
      "iata": "XZ"
    },
    {
      "name": "Yellowstone Club Private Shuttle",
      "iata": "Y1"
    },
    {
      "name": "Volaris",
      "iata": "Y4"
    },
    {
      "name": "Asia Wings",
      "iata": "Y5"
    },
    {
      "name": "NordStar Airlines",
      "iata": "Y7"
    },
    {
      "name": "Marusya Airways",
      "iata": "Y8"
    },
    {
      "name": "Kish Air",
      "iata": "Y9"
    },
    {
      "name": "Ciel Canadien",
      "iata": "YC"
    },
    {
      "name": "Gomelavia",
      "iata": "YD"
    },
    {
      "name": "Yellowtail",
      "iata": "YE"
    },
    {
      "name": "Yangon Airways Ltd.",
      "iata": "YH"
    },
    {
      "name": "Cyprus Turkish Airlines",
      "iata": "YK"
    },
    {
      "name": "Yamal Airlines",
      "iata": "YL"
    },
    {
      "name": "Montenegro Airlines",
      "iata": "YM"
    },
    {
      "name": "TransHolding System",
      "iata": "YO"
    },
    {
      "name": "Polet Airlines (Priv)",
      "iata": "YQ"
    },
    {
      "name": "SENIC AIRLINES",
      "iata": "YR"
    },
    {
      "name": "Régional",
      "iata": "YS"
    },
    {
      "name": "Yeti Airways",
      "iata": "YT"
    },
    {
      "name": "Mesa Airlines",
      "iata": "YV"
    },
    {
      "name": "Air Nostrum",
      "iata": "YW"
    },
    {
      "name": "Midwest Airlines",
      "iata": "YX"
    },
    {
      "name": "Virginwings",
      "iata": "YY"
    },
    {
      "name": "LSM AIRLINES ",
      "iata": "YZ"
    },
    {
      "name": "Zest Air",
      "iata": "Z2"
    },
    {
      "name": "Avient Aviation",
      "iata": "Z3"
    },
    {
      "name": "Zoom Airlines",
      "iata": "Z4"
    },
    {
      "name": "ZABAIKAL AIRLINES",
      "iata": "Z6"
    },
    {
      "name": "REDjet",
      "iata": "Z7"
    },
    {
      "name": "Amaszonas",
      "iata": "Z8"
    },
    {
      "name": "Flightlink Tanzania",
      "iata": "Z9"
    },
    {
      "name": "Interavia Airlines",
      "iata": "ZA"
    },
    {
      "name": "Access Air",
      "iata": "ZA"
    },
    {
      "name": "Monarch Airlines",
      "iata": "ZB"
    },
    {
      "name": "Air Bourbon",
      "iata": "ZB"
    },
    {
      "name": "Korongo Airlines",
      "iata": "ZC"
    },
    {
      "name": "Eastar Jet",
      "iata": "ZE"
    },
    {
      "name": "Athens Airways",
      "iata": "ZF"
    },
    {
      "name": "Viva Macau",
      "iata": "ZG"
    },
    {
      "name": "Shenzhen Airlines",
      "iata": "ZH"
    },
    {
      "name": "Aigle Azur",
      "iata": "ZI"
    },
    {
      "name": "Zambezi Airlines (ZMA)",
      "iata": "ZJ"
    },
    {
      "name": "Great Lakes Airlines",
      "iata": "ZK"
    },
    {
      "name": "Regional Express",
      "iata": "ZL"
    },
    {
      "name": "Apache Air",
      "iata": "ZM"
    },
    {
      "name": "Zenith International Airline",
      "iata": "ZN"
    },
    {
      "name": "Zabaykalskii Airlines",
      "iata": "ZP"
    },
    {
      "name": "Locair",
      "iata": "ZQ"
    },
    {
      "name": "Sama Airlines",
      "iata": "ZS"
    },
    {
      "name": "Air Wisconsin",
      "iata": "ZW"
    },
    {
      "name": "Japan Regio",
      "iata": "ZX"
    },
    {
      "name": "Ada Air",
      "iata": "ZY"
    },
    {
      "name": "Zz",
      "iata": "ZZ"
    },
    {
      "name": "KMV",
      "iata": "МИ"
    },
    {
      "name": "Polar Airlines",
      "iata": "ЯП"
    },
    {
      "name": "Tom\\\\'s & co airliners",
      "iata": "&T"
    },
    {
      "name": "Avilu",
      "iata": ".."
    },
    {
      "name": "Wilderness Air",
      "iata": ";;"
    },
    {
      "name": "Jayrow",
      "iata": "\\\\'"
    },
    {
      "name": "Tyrolean Airways",
      "iata": "\\N"
    },
    {
      "name": "Whitaker Air",
      "iata": "^^"
    },
    {
      "name": "Private flight",
      "iata": "-"
    },
    {
      "name": "Unknown",
      "iata": "-"
    },
    {
      "name": "U.S. Air",
      "iata": "-+"
    },
    {
      "name": "ELK Airways",
      "iata": "--"
    },
    {
      "name": "Bobb Air Freight",
      "iata": "01"
    },
    {
      "name": "Blue Air",
      "iata": "0B"
    },
    {
      "name": "Darwin Airline",
      "iata": "0D"
    },
    {
      "name": "Sprintair",
      "iata": "0G"
    },
    {
      "name": "Copenhagen Express",
      "iata": "0X"
    },
    {
      "name": "Abacus International",
      "iata": "1B"
    },
    {
      "name": "OneChina",
      "iata": "1C"
    },
    {
      "name": "CB Airways UK ( Interliging Flights )",
      "iata": "1F"
    },
    {
      "name": "NetJets Europe",
      "iata": "1I"
    },
    {
      "name": "Novair",
      "iata": "1I"
    },
    {
      "name": "NetJets",
      "iata": "1I"
    },
    {
      "name": "1Time Airline",
      "iata": "1T"
    },
    {
      "name": "Deutsche Bahn",
      "iata": "2A"
    },
    {
      "name": "Aerocondor",
      "iata": "2B"
    },
    {
      "name": "Aero VIP (2D)",
      "iata": "2D"
    },
    {
      "name": "Frontier Flying Service",
      "iata": "2F"
    },
    {
      "name": "Star Peru (2I)",
      "iata": "2I"
    },
    {
      "name": "Air Burkina",
      "iata": "2J"
    },
    {
      "name": "Aerolineas Galapagos (Aerogal)",
      "iata": "2K"
    },
    {
      "name": "Helvetic Airways",
      "iata": "2L"
    },
    {
      "name": "Moldavian Airlines",
      "iata": "2M"
    },
    {
      "name": "NextJet",
      "iata": "2N"
    },
    {
      "name": "Air Salone",
      "iata": "2O"
    },
    {
      "name": "Air Philippines",
      "iata": "2P"
    },
    {
      "name": "Air Cargo Carriers",
      "iata": "2Q"
    },
    {
      "name": "Haiti Ambassador Airlines",
      "iata": "2T"
    },
    {
      "name": "Air Guinee Express",
      "iata": "2U"
    },
    {
      "name": "Welcome Air",
      "iata": "2W"
    },
    {
      "name": "Regionalia Uruguay",
      "iata": "2X"
    },
    {
      "name": "My Indo Airlines",
      "iata": "2Y"
    },
    {
      "name": "JobAir",
      "iata": "3B"
    },
    {
      "name": "Air Choice One",
      "iata": "3E"
    },
    {
      "name": "Fly Colombia ( Interliging Flights )",
      "iata": "3F"
    },
    {
      "name": "AsiaCargo Express",
      "iata": "3G"
    },
    {
      "name": "Atlant-Soyuz Airlines",
      "iata": "3G"
    },
    {
      "name": "Air Comet Chile",
      "iata": "3I"
    },
    {
      "name": "Jetstar Asia Airways",
      "iata": "3K"
    },
    {
      "name": "Intersky",
      "iata": "3L"
    },
    {
      "name": "Air Arabia Maroc",
      "iata": "3O"
    },
    {
      "name": "Tiara Air",
      "iata": "3P"
    },
    {
      "name": "Yunnan Airlines",
      "iata": "3Q"
    },
    {
      "name": "Moskovia Airlines",
      "iata": "3R"
    },
    {
      "name": "Air Antilles Express",
      "iata": "3S"
    },
    {
      "name": "Turan Air",
      "iata": "3T"
    },
    {
      "name": "Sichuan Airlines",
      "iata": "3U"
    },
    {
      "name": "Malawian Airlines",
      "iata": "3W"
    }
  ] as Airline[]
export default airlines