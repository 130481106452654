import { IonBadge, IonButton, IonCheckbox, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, closeCircleOutline, search, trash } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import Booking, { BookingFilter } from '../../types/booking.type';
import { pluralTitle } from '../../configs/reservation.crud.config';
import VehicleType from '../../types/vehicle.type';
import { reservationStatusList, reservationTypes } from '../../data/reservation.data';
type Props = {
  vehicleTypes: VehicleType[]
  filter: BookingFilter
  onApplyFilter: (item: BookingFilter) => void
}
const Filter: React.FC<Props> = ({ vehicleTypes, filter, onApplyFilter }) => {
  const modal = useRef<HTMLIonModalElement>(null)

  const identifier = useRef<HTMLIonInputElement>(null)
  const customer = useRef<HTMLIonInputElement>(null)
  const driver = useRef<HTMLIonInputElement>(null)
  const next_min = useRef<HTMLIonInputElement>(null)

  const type = useRef<HTMLIonSelectElement>(null)
  const status = useRef<HTMLIonSelectElement>(null)
  const vehicle_type_id = useRef<HTMLIonSelectElement>(null)
  const driver_allocated = useRef<HTMLIonSelectElement>(null)
  const driver_late = useRef<HTMLIonSelectElement>(null)
  const payment_done = useRef<HTMLIonSelectElement>(null)
  const payout_done = useRef<HTMLIonSelectElement>(null)

  const start_at = useRef<HTMLIonDatetimeElement>(null)
  const [startDateSelected, setStartDateSelected] = useState(false)
  const [filterCount, setFilterCount] = useState(Object.keys(filter).filter(item => item !== "page").length)
  useEffect(() => {
    if (identifier.current) identifier.current.value = filter.identifier
    if (customer.current) customer.current.value = filter.customer
    if (driver.current) driver.current.value = filter.driver
    if (type.current) type.current.value = filter.type
    if (status.current) status.current.value = filter.status
    if (vehicle_type_id.current) vehicle_type_id.current.value = filter.vehicle_type_id
    if (start_at.current) start_at.current.value = filter.start_at
    if (driver_allocated.current) driver_allocated.current.value = filter.driver_allocated
    if (driver_late.current) driver_late.current.value = filter.driver_late
    if (payment_done.current) payment_done.current.value = filter.payment_done
    if (payout_done.current) payout_done.current.value = filter.payout_done

  }, [])
  function getNewData() {
    const newFilter = { page: 1 } as BookingFilter
    if (identifier.current?.value) newFilter.identifier = identifier.current.value as string
    if (customer.current?.value) newFilter.customer = customer.current.value as string
    if (driver.current?.value) newFilter.driver = driver.current.value as string
    if (next_min.current?.value) newFilter.next_min = parseInt(next_min.current.value.toString())
    if (type.current?.value) newFilter.type = type.current.value as string
    if (status.current?.value) newFilter.status = status.current.value as string
    if (vehicle_type_id.current?.value) newFilter.vehicle_type_id = vehicle_type_id.current.value as number
    if (start_at.current?.value) newFilter.start_at = start_at.current.value as string
    if (driver_allocated.current?.value) newFilter.driver_allocated = driver_allocated.current.value as string
    if (payment_done.current?.value) newFilter.payment_done = payment_done.current.value as string
    if (payout_done.current?.value) newFilter.payout_done = payout_done.current.value as string
    if (driver_late.current?.value) newFilter.driver_late = driver_late.current.value as string
    return newFilter
  }
  function onChange() {
    const newFilter = getNewData()
    setFilterCount(Object.keys(newFilter).filter(item => item !== "page").length)
    setStartDateSelected(!!newFilter.start_at)
    onApplyFilter(newFilter)
  }
  function clearDateTime() {
    if (start_at.current) start_at.current.value = ""
    const newFilter = getNewData()
    setStartDateSelected(false)
    onApplyFilter(newFilter)
  }
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton onClick={() => modal.current?.present()} color='dark'>
          <IonIcon icon={search}></IonIcon>
          {filterCount > 0 && <IonBadge className='filter'>{filterCount}</IonBadge>}
        </IonFabButton>
      </IonFab>
      <IonModal ref={modal} keepContentsMounted={true} initialBreakpoint={0.9} breakpoints={[0, 0.9]}>
        <IonHeader>
          <IonToolbar color="light">
            <IonTitle>Filter {pluralTitle}</IonTitle>
            <IonButton slot='end' onClick={() => modal.current?.dismiss()} fill='clear' color='danger'><IonIcon icon={closeCircleOutline} /></IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol style={{ padding: 0 }}>
              <IonItem color='light'>
                <IonLabel position='floating'>Reservation ID</IonLabel>
                <IonInput ref={identifier} onIonChange={onChange} debounce={500} clearInput={true} />
              </IonItem>
            </IonCol>
            <IonCol style={{ padding: 0 }}>
              <IonItem color='light'>
                <IonLabel position='floating'>Customer Name, Email or Phone</IonLabel>
                <IonInput ref={customer} onIonChange={onChange} debounce={500} clearInput={true} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem color='light'>
            <IonLabel position='floating'>Driver Name, Email or Phone</IonLabel>
            <IonInput ref={driver} onIonChange={onChange} debounce={500} clearInput={true} />
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Type</IonLabel>
            <IonSelect ref={type} onIonChange={onChange} slot='end' interface='action-sheet'>
              <IonSelectOption value={""}>Any</IonSelectOption>
              {reservationTypes.map((item, index) => (<IonSelectOption key={index} value={item}>{item}</IonSelectOption>))}
            </IonSelect>
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Status</IonLabel>
            <IonSelect ref={status} onIonChange={onChange} slot='end' interface='action-sheet'>
              <IonSelectOption value={""}>Any</IonSelectOption>
              {reservationStatusList.map((item, index) => (<IonSelectOption key={index} value={item}>{item
                .replace("DOS", "Driver Offer Sent (DOS)")
                .replace("POB", "Passenger On Board (POB)")
                .replace('DOW', 'Driver On The Way (DOW)')}</IonSelectOption>))}
            </IonSelect>
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Vehicle Type</IonLabel>
            <IonSelect ref={vehicle_type_id} onIonChange={onChange} slot='end' interface="action-sheet">
              {vehicleTypes.map((item, index) => (<IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>))}
            </IonSelect>
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Start Date</IonLabel>
            <IonDatetimeButton slot='end' datetime="datetime-2"></IonDatetimeButton>
            {startDateSelected && <IonButton onClick={clearDateTime} slot='end' fill='clear' color="danger"><IonIcon icon={trash} /></IonButton>}
            <IonModal keepContentsMounted={true}>
              <IonDatetime presentation="date" onIonChange={onChange} ref={start_at} id="datetime-2"></IonDatetime>
            </IonModal>
          </IonItem>
          <IonItem color='light'>
            <IonLabel>Starting With in Next</IonLabel>
            <IonInput slot='end' ref={next_min} onIonChange={onChange} type='number' placeholder='Type' clearInput={true} />
            <IonText slot='end'>Minute(s)</IonText>
          </IonItem>
          <IonRow>
            <IonCol size='6'>

              <IonItem color='light'>
                <IonLabel>Driver Allocated</IonLabel>
                <IonSelect ref={driver_allocated} onIonChange={onChange} slot='end' interface="action-sheet">
                  <IonSelectOption value={""}>Any</IonSelectOption>
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size='6'>

              <IonItem color='light'>
                <IonLabel>Driver Will Late</IonLabel>
                <IonSelect ref={driver_late} onIonChange={onChange} slot='end' interface="action-sheet">
                  <IonSelectOption value={""}>Any</IonSelectOption>
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='6'>

              <IonItem color='light'>
                <IonLabel>Payment Settled</IonLabel>
                <IonSelect ref={payment_done} onIonChange={onChange} slot='end' interface="action-sheet">
                  <IonSelectOption value={""}>Any</IonSelectOption>
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size='6'>

              <IonItem color='light'>
                <IonLabel>Payout Settled</IonLabel>
                <IonSelect ref={payout_done} onIonChange={onChange} slot='end' interface="action-sheet">
                  <IonSelectOption value={""}>Any</IonSelectOption>
                  <IonSelectOption value={"Yes"}>Yes</IonSelectOption>
                  <IonSelectOption value={"No"}>No</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Filter;
