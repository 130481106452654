import { IonBadge, IonContent, IonHeader, IonIcon, IonItem, IonText, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { carSport, cart, home, notifications, paperPlane, person, power, storefront } from 'ionicons/icons';
import { apiBaseUrl, appSubDir } from '../config';
import { defaultData, useAppContext, useAppContextUpdater } from '../services/app-context.service';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import User from '../types/user.type';
import { UserClass } from '../services/user.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight, faPerson, faTable, faUsers } from '@fortawesome/free-solid-svg-icons'

const Menu: React.FC = () => {
  const data = useAppContext() as any
  const setData = useAppContextUpdater() as any
  const [user, setUser] = useState(data.user as User)
  const history = useHistory()
  function fetchData() {
    const userObj = new UserClass(data.user)
    userObj.fetchFromOnline()?.then((userDetails: User) => {
      setUser(userDetails)
    })
  }
  useIonViewDidEnter(() => {
    fetchData()
    const tabBar = document.querySelector('ion-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  })
  async function afterLogout() {
    window.localStorage.removeItem('user')
    setData(defaultData)
    window.location.replace(appSubDir)
  }
  function doLogOut() {
    if (data.user.token) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + data.user.token);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(apiBaseUrl + "logout", requestOptions as RequestInit)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.error('error', error))
        .finally(afterLogout)
    } else {
      afterLogout()
    }
  }
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <img slot='start' style={{ height: 40, marginLeft: 10 }} src='assets/icon/icon.png' alt="" />
          <IonTitle>Blackwing</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem routerLink={appSubDir + 'home'}>
          <IonIcon slot='start' icon={home}></IonIcon>
          <h5>Dashboard</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'reservations'}>
          <IonIcon slot='start' icon={paperPlane}></IonIcon>
          <h5>Reservations</h5>
        </IonItem>
        {/* <IonItem routerLink={appSubDir + 'home'}>
          <IonText slot='start'><FontAwesomeIcon icon={faCarSide} /></IonText>
          <h5>Dispatch</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'home'}>
          <IonText slot='start' style={{ width: 25, textAlign: "center" }}><FontAwesomeIcon icon={faFileInvoice} /></IonText>
          <h5>Invoices</h5>
        </IonItem> */}
        <IonItem routerLink={appSubDir + 'drivers'}>
          <IonIcon slot='start' icon={carSport}></IonIcon>
          <h5>Drivers</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'customers'}>
          <IonIcon slot='start' icon={cart}></IonIcon>
          <h5>Customers</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'businesses'}>
          <IonIcon slot='start' icon={storefront}></IonIcon>
          <h5>Business Accounts</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'users'}>
          <IonText slot='start'><FontAwesomeIcon icon={faUsers} /></IonText>
          <h5>User Management</h5>
        </IonItem>

        <IonItem style={{ marginTop: 10 }}>
          <h5>Settings</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'settings/vehicle-types'}>
          <IonIcon slot='start' icon={carSport} />
          <h5>Vehicle Types</h5>
        </IonItem>
        <IonItem routerLink={appSubDir + 'settings/driver-teams'}>
          <IonText slot='start' style={{ width: 25, textAlign: "center" }}><FontAwesomeIcon icon={faTable} /></IonText>
          <h5>Driver Teams</h5>
        </IonItem>

        <IonItem style={{ marginTop: 10 }}>
          <h5>Account Functions</h5>
        </IonItem>
        <IonItem button={true}>
          <IonIcon slot='start' icon={notifications} />
          <h5>Notifications</h5>
          <IonBadge slot='end' color="danger">11</IonBadge>
        </IonItem>
        <IonItem button={true}>
          <IonIcon slot='start' icon={person} />
          <h5>My Profile</h5>
        </IonItem>
        <IonItem button={true} onClick={doLogOut}>
          <IonIcon slot='start' icon={power} />
          <h5>Logout</h5>
        </IonItem>

      </IonContent>
    </>
  );
};

export default Menu;
