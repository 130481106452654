import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonPopover, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, cloudUpload, home, mail, person, phonePortrait } from 'ionicons/icons';
import { useRef, useState } from 'react';
import User from '../../types/user.type';
import { useAppContext } from '../../services/app-context.service';
import { apiBaseUrl } from '../../config';
type Props = {
    setCustomer?: (item: User) => void;
}
const BookingContactCreate: React.FC<Props> = ({ setCustomer }) => {
    const store = useAppContext() as any

    const fName = useRef({ value: "" } as any)
    const email = useRef({ value: "" } as any)
    const phone = useRef({ value: "" } as any)
    const lName = useRef({ value: "" } as any)
    const modal = useRef<HTMLIonModalElement>(null)

    const [errMsg, SetErrMsg] = useState({} as any)

    const [buttonLoading, SetButtonLoading] = useState(false)
    const [disabled, SetDisabled] = useState(true)
    const onChange = () => {
        SetDisabled(!(
            fName.current && fName.current.value &&
            lName.current.value &&
            email.current.value &&
            phone.current.value
        ))
    }
    function onOpen(){
        modal.current?.present()
        SetErrMsg({} as any)
    }
    const onSubmit = () => {

        SetButtonLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + store.user.token);
        const formdata = new FormData();
        formdata.append("f_name", fName.current.value.trim());
        formdata.append("l_name", lName.current.value.trim());
        formdata.append("email", email.current.value.trim());
        formdata.append("phone", phone.current.value.trim());

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        } as any
        fetch(apiBaseUrl + "admin/users/create/customer", requestOptions).then((res) => (res.json()))
            .then((res: User) => {
                if (res.id) {
                    // toast.success('User added')
                    setCustomer && setCustomer(res)
                    fName.current.value = ""
                    lName.current.value = ""
                    email.current.value = ""
                    phone.current.value = ""
                } else {
                    throw res
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.message) {
                    SetErrMsg(err.message)
                }
            })
            .finally(() => SetButtonLoading(false))
    }
    return (
        <>
            <IonButton slot='end' color="dark" fill='clear' size='small' onClick={onOpen}><IonIcon icon={add} /></IonButton>
            <IonModal ref={modal} initialBreakpoint={0.77} breakpoints={[0, 0.25, 0.5,0.77, 0.90]} >
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle>Create New Booking Contact</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem className={`${!!errMsg.f_name && 'ion-invalid'}`}>
                        <IonLabel position="floating"><IonIcon icon={person} />First Name</IonLabel>
                        <IonInput clearInput={true} ref={fName} onIonChange={onChange} />
                        {!!errMsg.f_name && <IonNote slot='error'>{errMsg.f_name.join(', ')}</IonNote>}
                    </IonItem>
                    <IonItem className={`${!!errMsg.l_name && 'ion-invalid'}`}>
                        <IonLabel position="floating"><IonIcon icon={home} />Last Name</IonLabel>
                        <IonInput clearInput={true} ref={lName} onIonChange={onChange} />
                        {!!errMsg.l_name && <IonNote slot='error'>{errMsg.l_name.join(', ')}</IonNote>}
                    </IonItem>
                    <IonItem className={`${!!errMsg.phone && 'ion-invalid'}`}>
                        <IonLabel position="floating"><IonIcon icon={phonePortrait} />Mobile</IonLabel>
                        <IonInput clearInput={true} ref={phone} onIonChange={onChange} />
                        {!!errMsg.phone && <IonNote slot='error'>{errMsg.phone.join(', ')}</IonNote>}
                    </IonItem>
                    <IonItem className={`${!!errMsg.email && 'ion-invalid'}`}>
                        <IonLabel position="floating"><IonIcon icon={mail} />Email</IonLabel>
                        <IonInput clearInput={true} ref={email} onIonChange={onChange} />
                        {!!errMsg.email && <IonNote slot='error'>{errMsg.email.join(', ')}</IonNote>}
                    </IonItem>
                    <IonButton disabled={disabled} onClick={onSubmit} expand='block' color="medium">
                        {!buttonLoading && <IonIcon icon={cloudUpload} slot="start" />}
                        {buttonLoading && <IonSpinner slot="start" name="lines-sharp"></IonSpinner>}
                        {buttonLoading ? "Creating New Customer" : "Create New Customer"}
                    </IonButton>
                </IonContent>
            </IonModal>
        </>
    );
};

export default BookingContactCreate;
