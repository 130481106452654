import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonRow, useIonViewDidEnter } from '@ionic/react';
import { useAppContext } from '../services/app-context.service';
import TopBar from '../components/TopBar';
import DriverAllocated from '../components/Home/DriverAllocated';
import DriverLate from '../components/Home/DriverLate';
import './Home.scss';
import Pob from '../components/Home/Pob';
import MonthlyReport from '../components/Home/MonthlyReport';
import TopDue from '../components/Home/TopDue';

const Home: React.FC = () => {
  const data = useAppContext() as any
  useIonViewDidEnter(() => {
  })

  return (
    <>
      <TopBar title='Dashboard' />
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd='4'>
              <DriverAllocated/>
            </IonCol>
            <IonCol sizeMd='4'>
              <DriverLate/>
            </IonCol>
            <IonCol sizeMd='4'>
              <Pob/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeMd='4'>
              <MonthlyReport/>
            </IonCol>
            <IonCol sizeMd='4'>
              <TopDue/>
            </IonCol>
            <IonCol sizeMd='4'>
              <IonItem>
                <h4>Top Driver Payable</h4>
                <IonButton slot='end' fill='clear' color='dark' >View All</IonButton>
              </IonItem>
              <div className='table-responsive dashboard'>
                <table className='table-scroll'>
                  <thead>
                    <tr>
                      <th>Driver</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Finlay Page - finlaypage@rhyta.com</td>
                      <td>£ 7271.38</td>
                    </tr>
                    <tr>
                      <td>Elise Cameron - elisecameron@jourrapide.com</td>
                      <td>£ 1428.01</td>
                    </tr>
                    <tr>
                      <td>Zoe Farmer - zoeFarmer@teleworm.us</td>
                      <td>£ 5763.66</td>
                    </tr>
                    <tr>
                      <td>Angeline Sweeney - angelineMSweeney@rhyta.com</td>
                      <td>£ 3723.00</td>
                    </tr>
                    <tr>
                      <td>Edward Kornegay - c3@blackwing.test</td>
                      <td>£ 3093.14</td>
                    </tr>
                    <tr>
                      <td>Finlay Page - finlaypage@rhyta.com</td>
                      <td>£ 7271.38</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <Body /> */}
        {/* <SimpleMap />
        <NotificationModal /> */}
      </IonContent>
    </>
  );
};

export default Home;
